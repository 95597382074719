import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import {imagePath} from "../../../util/Constants";
import Cookies from "universal-cookie";
import {getBusinessProductsById,getBusinessProductDetailById} from "../../../api/productServices";
import {addToCart} from '../../../actions';
import { connect } from 'react-redux';
import {addItemsTocart} from "../../../api/orderServices";
import {toast} from "react-toastify";
import LinesEllipsis from "react-lines-ellipsis";
import StarRatingComponent from "react-star-rating-component";

class ProductItem extends Component {

    constructor(props){
        super(props)
        this.handleChange=this.handleChange.bind(this);
        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            productData:{},
            cartItems:{},
            variationValue:null
        }
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }
    onOpenModal = () => {
        this.setState({ open: true });
        this.fetchByBusinessProduct(this.props.product);
        if(this.props.product.variationData!=null && this.props.product.variationData.length>0){
        this.state.variationValue=this.props.product.variationData[0].variation

        }

    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if(this.props.product.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        }else{
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    fetchByBusinessProduct(product){
        getBusinessProductsById(product.id).then(res=>{

        if(res.data.data.length>0)
        {
            getBusinessProductDetailById(res.data.data[0].bcode,res.data.data[0].code).then(res=>{
                this.state.productData = res.data.data;
                this.setState({})
            });
        }
    });
    
}

handleChange(e) {
    this.setState({ variationValue: e.target.value });
    console.log(this.state.variationValue);
  }

addItemsToCart(product,quantity){

    try {
            console.log("Add to cart");
            const cookies = new Cookies();
            let userId=cookies.get('userId');
            console.log(userId);
            if(userId===null||userId===undefined)
            {
                toast.error("Please login");
                window.location.assign("/signin");
            }
            else {
                let data = {};
            let variation={};
            if(product.variationData!=null){
                product.variationData.forEach(variant => {
                    if(variant.variation==this.state.variationValue){
                        let variationName=variant.variation;
                        let variationId=variant.variationId;
                        variation={
                            "variation":variationName,
                            "variationId":variationId
                        }
                        data.variation=variation;   
                    }
                });
            }
                data.businessId = product.businessId;
                data.homeDelivery = product.homeDelivery;
                data.productId = product.id;
                data.unitPrice = product.sellingPrice;
                data.quantity = quantity;
                data.totalPrice = product.sellingPrice * data.quantity;
                data.userId = cookies.get('userId');
                addItemsTocart(data).then(res => {
                    if (res.data.status === "Success") {
                        this.setState({cartItems:res.data.data.cartItems});
                        this.state.cartItems.map((product, key) => {
                            if(product.productId==data.productId){
                                 this.props.addToCart(product,product.quantity);
                            }
                           })
                        toast.success("Item added to cart")
                        window.location.assign("/cart");
                    }
                    else
                    {
                        toast.error(res.data.message)
                    }
                });
            }
        }
        catch (e) {
            console.log(e.toString());
        }
}

    render() {
        console.log(this.state.variationValue);
        const {product, symbol, addToCart, onAddToWishlistClicked, onAddToCompareClicked} = this.props;
        return (


                <div className="product-box">
                    <div className="img-wrapper">
                        <div className="lable-block">
                            {(product.new == true)? <span className="lable3">new</span> : ''}
                            {(product.sale == true)? <span className="lable4">on sale</span> : ''}

                        </div>
                        {product.bcode==null?
                        <div className="front" style={{'textAlign':'center'}}>
                        <Link to={`${process.env.PUBLIC_URL}/product/${product.code}`}>
                            <img src={product.assets  && product.assets[0] ?imagePath+product.assets[0].assetId:`${process.env.PUBLIC_URL}/assets/images/icon/placeholder.png`} alt="" className="img-fluid" style={{width:'200px',height:'200px',"object-fit": "contain"}} />
                        </Link>
                    </div>
                    :
                    <div className="front" style={{'textAlign':'center'}}>
                            <Link to={`${process.env.PUBLIC_URL}/product/${product.code}/business/${product.bcode}`}>
                                <img src={product.assets  && product.assets[0] ?imagePath+product.assets[0].assetId:`${process.env.PUBLIC_URL}/assets/images/icon/placeholder.png`} alt="" className="img-fluid" style={{width:'200px',height:'200px',"object-fit": "contain"}} />
                            </Link>
                        </div>
                     }
                        
                        <div className="cart-info cart-wrap">
                           {/* <button title="Add to cart" onClick={addToCart(this.state)}>
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            </button>*/}
                           {/* <a href="javascript:void(0)" title="Add to Wishlist" onClick={onAddToWishlistClicked}>
                                <i className="fa fa-heart" aria-hidden="true"></i>
                            </a>*/}
                            <a href="javascript:void(0)" data-toggle="modal"
                               data-target="#quick-view"
                               title="Quick View"
                               onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"></i></a>
                            <Link to={`${process.env.PUBLIC_URL}/compare`} title="Compare" onClick={onAddToCompareClicked}>
                                <i className="fa fa-refresh" aria-hidden="true"></i></Link>
                        </div>
                        {product.variants?
                        <ul className="product-thumb-list">
                            { product.variants.map((vari, i) =>
                                <li className={`grid_thumb_img ${(vari.images === this.state.image)?'active':''}`} key={i}>
                                    <a href="javascript:void(0)" title="Add to Wishlist">
                                        <img src={`${vari.images}`} onClick={() => this.onClickHandle(vari.images)} />
                                    </a>
                                </li>)}
                        </ul>:''}

                    </div >
                    {product.bcode==null?
                    <div className="product-buttons" style={{ "paddingTop": "10px" }}>
                    <Link to={`${process.env.PUBLIC_URL}/product/${product.code}`}>
                        <h6>{product.name}</h6>
                        </Link>
                    </div>
                    :
                    <div className="product-buttons" style={{ "paddingTop": "10px" }}>
                    <Link to={`${process.env.PUBLIC_URL}/product/${product.code}/business/${product.bcode}`}>
                        <h6>{product.name}</h6>
                        </Link>
                    </div>
                     }
                    <div className="product-right">
                    <StarRatingComponent
                                    name="rating"
                                    starCount={5}
                                    editing={false}
                                    starColor="#ffb400"
                                    renderStarIcon={() => <i className="fa fa-star"></i>}
                                    renderStarIconHalf={() => <i className="fa fa-star-half-o" style={{"color":"#ffb400"}}></i>}
                                    emptyStarColor="#e9ecef"
                                    value={product.averageProductRating}
                                />    
                    
                 

                    {product.variationData!=null && product.variationData.length>0  ?
                    <div>
                            <h4>{symbol}{product.variationData[0].sellingPrice + " "}
             <del>{product.variationData[0].sellingPrice===product.variationData[0].writtenPrice?"":symbol +product.variationData[0].writtenPrice}</del>
                <span>{product.variationData[0].sellingPrice===product.variationData[0].writtenPrice?"":product.variationData[0].discount!=undefined && product.variationData[0].discount+ '% off'}</span></h4>
                
                    </div>
                               
                                   :
                                   <div>
                        <h4>{symbol}{product.sellingPrice+" "}
                        <del>{product.sellingPrice===product.writtenPrice?"":symbol +product.writtenPrice}</del>
                           <span>{product.sellingPrice===product.writtenPrice?"":product.discount!=null && product.discount!=undefined && product.discount+ '% off'}</span></h4>
                           
                           </div>
    }</div>     

                            {/*{product.variants?
                            <ul className="color-variant">
                                {product.variants.map((vari, i) => {
                                    return (
                                        <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                })}
                            </ul>:''}*/}
                        
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-6  col-xs-12">
                                            <div style={{"text-align":"center",position:"sticky",top:"35%"}} >
                                                <img src={product.assets && product.assets[0]?imagePath+product.assets[0].assetId:`${process.env.PUBLIC_URL}/assets/images/icon/placeholder.png`} alt="" style={{width:'250px',height:'250px',"object-fit": "contain"}}  />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 rtl-text">
                                            <div className="product-right">
                                                <h3> {product.name} </h3>
                                                {/*<h3>{symbol}{product.price}</h3>*/}
                                                {product.variationData!=null && product.variationData.length>0  ?
                    <div>
                    <h4>{symbol}{product.variationData[0].sellingPrice}
             <del>{product.variationData[0].sellingPrice===product.variationData[0].writtenPrice?"":symbol +product.variationData[0].writtenPrice}</del>
                <span>{product.variationData[0].sellingPrice===product.variationData[0].writtenPrice?"":product.variationData[0].discount!=undefined && product.variationData[0].discount+ '% off'}</span></h4>
               
                    </div>
                               
                                   :
                                   <div>
                                   <h4>{symbol}{product.sellingPrice}
                                   <del>{product.sellingPrice===product.writtenPrice?"":symbol +product.writtenPrice}</del>
                                      <span>{product.sellingPrice===product.writtenPrice?"":product.discount!=undefined && product.discount+ '% off'}</span></h4>
                                      
                                      </div>
    }
                                                {product.variants?
                                                <ul className="color-variant">
                                                    {product.variants.map((vari, i) =>
                                                        <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                                    }
                                                </ul>:''}
                                                <div className="border-product">
                                                    <h6 className="product-title">product details</h6>
                                                    {/*<p>{product.description}</p>*/}
                                                    {product.description != null ?
                                                        <LinesEllipsis
                                                            text={product.description}
                                                            maxLine='3'
                                                            ellipsis='...'
                                                            trimRight
                                                        /> : ""
                                                    }
                                                </div>
                                                <div className="product-description border-product">
                                                    {product.size?
                                                    <div className="size-box">
                                                        <ul>
                                                            {product.size.map((size, i) => {
                                                                return <li key={i}><a href="#">{size}</a></li>
                                                            })}
                                                        </ul>
                                                    </div>:''}
                                                    <h6 className="product-title">quantity</h6>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                              <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} style={{"border-top-left-radius": "25px",
                                                                    "border-bottom-left-radius": "25px"}} data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"></i>
                                                                </button>
                                                              </span>
                                                            <input type="text" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number" />
                                                                <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-right-plus" style={{"border-top-right-radius": "25px",
                                                                    "border-bottom-right-radius": "25px"}}
                                                                        onClick={this.plusQty} data-type="plus" data-field="">
                                                                <i className="fa fa-angle-right"></i>
                                                                </button>
                                                               </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {product.variationData!=null && product.variationData.length>0?
                   <div>
                    <h6 className="product-title">{product.variationName}</h6>
                    <select id="variation" name="variation" value={this.state.variationValue} style={{"width": "40%","height": "40px","border-radius": "25px","padding":"10px","margin-bottom":"10px","margin-top":"10px"}} onChange={this.handleChange}>
                    {product.variationData.map((e, key) => {
                     return <option key={key} value={e.variation}>{e.variation}</option>
                    })}
                    </select>
                    </div>:
                    <div>
                        </div>
                
                }
                                                {(this.state.productData.businessId!=null)?
                                                    <div className="product-buttons">
                                                        <button  className="btn btn-solid" onClick={() => this.addItemsToCart(this.state.productData, this.state.quantity)}  >add to cart</button>
                                                        <Link to={`${process.env.PUBLIC_URL}/product/${product.code}/business/${this.state.productData.bcode}`} className="btn btn-solid">view detail</Link>
                                                    </div>:
                                                    <div className="product-buttons">
                                                        <Link to={`${process.env.PUBLIC_URL}/product/${product.code}`} className="btn btn-solid">view detail</Link>
                                                    </div>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.cartList.cart,
    symbol: state.data.symbol,
})
export default connect(
    mapStateToProps,
    {addToCart}
)(ProductItem)
