import React, {Component} from 'react';
import Slider from 'react-slick';
import '../common/index.scss';
import {connect} from "react-redux";


// import custom Components
import RelatedProduct from "../common/related-product";
import Breadcrumb from "../common/breadcrumb";
import DetailsWithPrice from "./common/product/details-price";
import DetailsTopTabs from "./common/details-top-tabs";
import {addToCart, addToCartUnsafe, addToCompare, addToWishlist} from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import {getBusinessProductDetailById,fetchProductByCode, getBusinessProductsById} from "../../api/productServices";
import ProductItem from "../layouts/common/product-item";
import {imagePath} from "../../util/Constants";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";




class ProductDetail extends Component {

    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            productData:null,
            product:null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });

    }

    componentWillReceiveProps(newProps)
    {
        console.log("Url changes");
        this.state.productData=null;
        this.forceUpdate();
    }

    render(){

        var products = {
            fade: true
        };

       var productsnav = {
            slidesToShow: 3,
            slidesToScroll:1,
            swipeToSlide:true,
            draggable:true,
            focusOnSelect: true
        };
       
        const {symbol, addToCart, addToCartUnsafe, addToWishlist} = this.props;

        console.log("Product Id = "+this.props.match.params.code); 

        {

            if(this.state.productData===null)
            { 
                //getBusinessProductsById('5fe655b5e4b0c6fbf376cbac').then(res=>{
                    fetchProductByCode(this.props.match.params.code).then(res=>{
                        this.setState({product : res.data.data});
                        console.log(this.state.product);
                    });
                getBusinessProductsById(this.state.product!=null && this.state.product.id).then(res=>{

                    if(res.data.data.length>0)
                    {
                        getBusinessProductDetailById(res.data.data[0].bcode,res.data.data[0].code).then(res=>{
                            this.state.productData = res.data.data;
                            console.log(this.state.productData);
                            this.setState({})
                        });
                    }
                    else
                    {
                        this.state.productData = this.state.product;
                        console.log(this.state.productData);
                        this.setState({})
                    }

                });

                return(
                    <div>
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div >
                                            <div style={{height:"250px"}}>
                                                <div className="loading-cls"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }
            else
            {
                return (
                    <div>

                        <Breadcrumb title={'Product/ '+this.state.productData.productTypeName} />
                        {/*Section Start*/}
                        {/* {(this.state.productData)?*/}
                        <section >
                            <div className="collection-wrapper">
                                <div style={{"padding":"25px"}}>
                                    <div className="row">
                                        <div className="col-lg-6 product-thumbnail">
                                            <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                                {this.state.productData.assets.map((asset, index) =>
                                                    <div key={index}>
                                                        <ImageZoom image={imagePath+asset.assetId} className="img-fluid image_zoom_cls-0" />
                                                    </div>
                                                )}
                                            </Slider>
                                            <SmallImages item={this.state.productData} settings={productsnav} navOne={this.state.nav1} />
                                        </div>
                                        <DetailsWithPrice symbol={symbol} item={this.state.productData} navOne={this.state.nav1} addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe} addToWishlistClicked={addToWishlist} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*Section End*/}

                        <section className="tab-product m-0">
                            <div style={{ "padding": "25px" }}>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-12">
                                        <DetailsTopTabs productsData={this.state.productData} />
                                    </div>
                                </div>
                            </div>
                        </section>

                        <RelatedProduct productData={this.state.productData}/>
                    </div>
                )
            }
        }

        return(
            <div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    return {
        item: state.data.products.find(el => el.id == productId),
        symbol: state.data.symbol
    }
}



export default connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist}) (ProductDetail);
