import api from "./api";


export const getproductTypeList = () => {
    return api.get('/products-service/productType/list/roots/PRODUCT');
};

export const getproductTypeListByParentId = (id) => {
    return api.get('/products-service/productType/listByParentId/' + id);
};


export const getProductsBysearch = (data, page, limit) => {
    return api.post('/products-service/product/searchProducts?page=' + page + '&limit=' + limit, data);
};

export const fetchProductsBysearch = (data, productId, page, limit) => {
    return api.post('/products-service/product/searchProducts?excludedProducts=' + productId + '&page=' + page + '&limit=' + limit, data);
};

export const getBusinessProductsById = (id) => {
    return api.get('/products-service/businessproduct/search/byproduct/' + id + '?' + 'long=' + 0 + '&lat=' + 0);
};

export const getBusinessProductDetailById = (bcode, pcode) => {
    return api.get('/products-service/businessproduct/business/' + bcode + '/product/' + pcode);
};

export const fetchProductByCode = (code) => {
    return api.get('/products-service/product/' + code);
}

export const getSimilarProductsByProductTypeId = (businessId, productTypeId) => {
    return api.get('/products-service/business/' + businessId + "/productType/" + productTypeId + "/products?" + 'page=' + 1 + '&limit=' + 10);
};

export const getRatingBreakUp = (businessId, productId) => {
    return api.get('/products-service/rating/breakup?&productId=' + productId);
};
export const getRatingByProduct = (businessId, productId) => {
    return api.get('/products-service/rating/all?&productId=' + productId + '&page=' + 1 + '&limit=' + 5);
};

export const saveRating = (data) => {
    return api.post('/products-service/rating/', data);
};

export const getBusinessByCode = (code) => {
    return api.get('/products-service/business/' + code);
};

export const getBusinessProductsByBusinessId = (id, page, limit) => {
    return api.post('/products-service/businessproduct/' + id + '/product/search?' + "page=" + page + "&limit=" + limit);
};







