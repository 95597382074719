import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import {
    getBusinessByCode,
    getBusinessProductDetailById,
    getBusinessProductsByBusinessId,
    getBusinessProductsById
} from "../../api/productServices";
import {imagePath} from "../../util/Constants";

class CollectionLeftSidebar extends Component {

    constructor() {
        super();
        this.state = {
            layoutColumns:3,
            businessData:null,
            productData:null,
            businessId:null
        };
    }


    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }
    
    render (){
        console.log("Business Id = "+this.props.match.params.code);
        this.state.bcode=this.props.match.params.code;
        if(this.state.businessData===null)
        {
            getBusinessByCode(this.state.bcode).then(res => {

                if(res.data.status==="Success")
                {
                    this.state.businessData = res.data.data;
                        getBusinessProductsByBusinessId(this.state.businessData.id,1,20).then(res =>
                        {
                            if(res.data.status==="Success") {
                                if (res.data.data.length > 0)
                                {
                                    this.state.productData = res.data.data;
                                }
                                this.setState({})
                            }
                        });
                    } else {
                        return (
                            <div></div>
                        )
                    }
            });
        }
        else
        {
            return (
                <div>
                    {/*SEO Support*/}
                    <Helmet>
                        <title>Nbiz – MarketPlace</title>
                        <meta name="description" content="Nbiz – MarketPlace" />
                    </Helmet>
                    {/*SEO Support End */}

                    <Breadcrumb title={'NEAR BY SELLARS'}/>

                    <section className="section-b-space">
                        <div className="collection-wrapper">
                            <div style={{"padding":"25px"}}>
                                <div className="row">
                                  {/*  <div className="col-sm-3 collection-filter">

                                        <StickyBox offsetTop={20} offsetBottom={20}>
                                            <div>
                                                <Filter/>
                                                <NewProduct/>
                                                <div className="collection-sidebar-banner">
                                                    <a href="#">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </StickyBox>
                                        side-bar banner end here
                                    </div>*/}
                                    <div className="collection-content col">
                                        <div className="page-main-content ">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="top-banner-wrapper" style={{ "text-align": "center"}}>
                                                            <a href="#"><img src={imagePath+this.state.businessData.asset[0].assetId} className="img-fluid" alt=""/></a>
                                                            <div className="top-banner-content small-section">                                                           
                                                            <div className="rating">{this.RatingStars}</div>
                                                            <h4>{this.state.businessData.name}</h4>
                                                                {/*<h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>*/}
                                                                <p>{this.state.businessData.description}</p>
                                                            </div>
                                                        </div>
                                                        <div className="collection-product-wrapper">
                                                            <div className="product-top-filter">
                                                                <div className="container-fluid p-0">
                                                                    <div className="row">
                                                                        <div className="col-xl-12">
                                                                            <div className="filter-main-btn">
                                                                            <span onClick={this.openFilter}
                                                                                  className="filter-btn btn btn-theme"><i
                                                                                className="fa fa-filter"
                                                                                aria-hidden="true"></i> Filter</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*Products Listing Component*/}
                                                            <ProductListing colSize={this.state.layoutColumns} business={this.state.businessData.id} data={this.state.productData}/>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            )
        }

        return(
            <div></div>
        )


    }
}

export default CollectionLeftSidebar;
