import React, { Component } from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';

import {Slider3} from "../../../services/script"
import {imagePath} from "../../../util/Constants";
import {getTrendingCollection} from "../../../services";
import {connect} from "react-redux";
import {addToCart, addToCompare, addToWishlist} from "../../../actions";
import StarRatingComponent from "react-star-rating-component";

class NearBySellarList extends Component {

    constructor (props) {
        super (props)
    }


    render (){

        if(this.props.dataItems!==undefined)
        {
            return (

                <div style={{padding:"15px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...Slider3} className="slide-3 no-arrow ">
                                {
                                    this.props.dataItems.map((item,index) => {
                                       
                                        return (
                                            <div key={index}>

                                                <div className="col-md-12">
                                                    <Link to={`${process.env.PUBLIC_URL}/business/`+item.code} >
                                                        <div className="classic-effect"> {/* style={{
                                                            backgroundImage: "url(" + imagePath+item.primaryImageId + ")",
                                                            width:'100%',
                                                            height:'200px',
                                                        }}*/}
                                                            <img src={imagePath+item.primaryImageId } className="img-fluid" alt="" style={{width:'100%',height:'200px',"object-fit": "contain"}}/>
                                                            <span></span>
                                                        </div>
                                                    </Link>
                                                    <div className="blog-details">
                                                       
                                                        <StarRatingComponent
                                                            name="rating"
                                                            starCount={5}
                                                            editing={false}
                                                            starColor="#ffb400"
                                                            renderStarIcon={() => <i className="fa fa-star"></i>}
                                                            renderStarIconHalf={() => <i className="fa fa-star-half-o" style={{ "color": "#ffb400" }}></i>}
                                                            emptyStarColor="#e9ecef"
                                                            value={item.avgBusinessRating}
                                                        />
                                                        <h4 style={{ "color": "#000"}}>{item.name}</h4>
                                                                                                             
                                                        <hr className="style1" />                                                      
                                                    </div>
                                                    <h6 style={{ "color": "#000" }}>{item.address.fullAddress}</h6>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            )
        }
        else
        {
            return <div/>
        }
    }
}


export default NearBySellarList;



