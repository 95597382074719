import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

import {getBestSeller} from "../../services";
import {addToCart, addToWishlist, addToCompare} from "../../actions";
import ProductItem from '../layouts/common/product-item';
import {getSimilarProductsByProductTypeId,fetchProductsBysearch} from "../../api/productServices";


class RelatedProduct extends Component {

    constructor() {
        super();
        this.state = {
            relatedProducts:null
        };
    }

    render ()
    {
        const {productData,items, symbol, addToCart, addToWishlist, addToCompare} = this.props;
        if(this.state.relatedProducts==null) {
            if(productData!=null){
            if(productData.businessId!==null){
                getSimilarProductsByProductTypeId(productData.businessId, productData.productTypeId).then(res => {
                    this.state.relatedProducts = res.data.data;
                    this.setState({})
                });
            }else{
                let data={};
                let productTypeIds = [];
                productTypeIds.push(productData.productTypeId);
                data.productTypeIds=productTypeIds;
                fetchProductsBysearch(data,productData.id,1,25).then(res => {
                    this.state.relatedProducts = res.data.data;
                    console.log(this.state.relatedProducts);
                    this.setState({})
                });

            }
        }
            
               /* return (
                    <section className="section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 product-related">
                                    <h2>related products</h2>
                                </div>
                            </div>
                            <div className="row search-product">
                                {this.state.relatedProducts.map((product, index) =>
                                    <div key={index} className="col-xl-2 col-md-4 col-sm-6">
                                        <ProductItem product={product} symbol={symbol}
                                                     onAddToCompareClicked={() => addToCompare(product)}
                                                     onAddToWishlistClicked={() => addToWishlist(product)}
                                                     onAddToCartClicked={() => addToCart(product, 1)} key={index}/>
                                    </div>)
                                }
                            </div>
                        </div>
                    </section>
                )*/
           
        }
        else
        {
            return (
                <section className="section-b-space">
                    <div style={{ "padding": "25px" }}>
                        <div className="row">
                            <div className="col-12 product-related">
                                <h2>related products</h2>
                            </div>
                        </div>
                        <div className="row search-product">
                            {this.state.relatedProducts.map((product, index) =>
                                <div key={index} className="col-xl-2 col-md-4 col-sm-4">
                                    <ProductItem product={product} symbol={symbol}
                                                 onAddToCompareClicked={() => addToCompare(product)}
                                                 onAddToWishlistClicked={() => addToWishlist(product)}
                                                 onAddToCartClicked={() => addToCart(product, 1)} key={index}/>
                                </div>)
                            }
                        </div>
                    </div>
                </section>
            )
        }

        return(<div></div>)
    }
}

function mapStateToProps(state) {
    return {
        items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(RelatedProduct);
