import api from "./api";


export const userLogin = (data) => {
    return api.post('/user-service/login', data);
};

export const createUser = (data) => {
    return api.post('user-service/customer/register', data);
};

export const verifyOTP = (registrationId, otp) => {
    return api.post('/user-service/customer/otp/register?registrationId=' + registrationId + "&otp=" + otp);
};

export const resendOTP = (loginId) => {
    return api.get('/user-service/resendPasswordOTP?loginId=' + loginId + '&generationType=SMS');
}

export const generateOtp = (loginId) => {
    return api.get('/user-service/generatePasswordOTP?loginId=' + loginId + '&generationType=SMS');
};
export const setPassword = (loginId, otp, password) => {
    return api.post('/user-service/resetPasswordWithOtp?loginId=' + loginId + '&otp=' + otp + '&newPassword=' + password);
};

export const changePassword = (loginId, newPassword) => {
    return api.post('/user-service/user/updatePassword?loginId=' + loginId + '&newPassword=' + newPassword);
};

export const logout = (loginId) => {
    return api.post('/user-service/logout?loginId=' + loginId);
};

export const getProfile = () => {
    return api.get('/user-service/user/viewProfile');
}

export const updateProfile = (data) => {
    return api.post('/user-service/user/updateProfile', data);
}

export const getAddress = () => {
    return api.get('/user-service/preferences/location/');
}

export const addAddress = (data) => {
    return api.post('/user-service/preferences/location/', data);
}
export const deleteUserAddress = (id) => {
    return api.delete('/user-service/preferences/location/?locPrefId=' + id);
}
export const editUserAddress = (data) => {
    return api.put('/user-service/preferences/location/', data);
}

export const deviceRegister = (data) => {
    return api.post('/user-service/device/register?overwrite=true', data);
};
