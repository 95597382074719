import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {generateOtp} from "../../api/userServices";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";
class ForgetPassword extends Component {

    constructor (props) {
        super (props)
        this.onInputchange=this.onInputchange.bind(this);
        this.onForgotPassword=this.onForgotPassword.bind(this);
    }

    render (){

        const cookies = new Cookies();
        return (
            <div>
                <Breadcrumb title={'forget password'}/>
                
                
                {/*Forget Password section*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Forget Your Password</h2>
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="loginId" name="loginId" onChange={this.onInputchange}
                                                   placeholder="Enter Your Mobile Number" required="" />
                                        </div>
                                        <button className="btn btn-solid" onClick={this.onForgotPassword}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    onForgotPassword(e)
    {
        e.preventDefault();

        generateOtp(this.state.loginId).then(res =>
            {
                console.log(this.state.loginId);
                toast.success(res.data.message);
                if(res.data.status==="Success")
                {
                    const cookies = new Cookies();
                    cookies.set('loginId', res.data.data);
                    this.props.history.push({
                        pathname : '/pages/generate-password',
                        state :{
                                 loginId : this.state.loginId
                               }
                        });
                    //this.props.history.push("/pages/generate-password");
                }
            });
       
    }

}

export default ForgetPassword