import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';

// Import custom components
import TopCollection from './top-collection';
import SpecialProducts from "../common/products";
import NearBySellarList from "../common/NearBySellarList";
import Instagram from "../common/instagram";
import LogoBlock from "../common/logo-block";
import ProductTabs from '../common/productTabs';


import {
    svgFreeShipping,
    svgservice,
    svgoffer
} from "../../../services/script"
import {getBanner, getHome,getRecentlyViewed} from "../../../api/homeServices";
import {imagePath} from "../../../util/Constants";
import {getproductTypeList} from "../../../api/productServices";
import ProductItem from '../common/product-item';


class Fashion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            banners: [],
            featuredProducts: [],
            nearBySellar: [],
            productTypes: [],
            productViews:[],
            recentlyView:[],
            columnSize:3,
        };
    }

    componentDidMount() {

        //document.getElementById("color").setAttribute("href", `#` );
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color1.css` );
        //this.banners=getHome();
        getBanner().then(res => {
            console.log(res.data);
            this.banners=res.data.data;
            this.setState({});
        });

        getHome().then(res => {
            this.featuredProducts=res.data.data.FEATURED_PRODUCTS;
            this.nearBySellar=res.data.data.LOCAL_BUSINESS;
            this.productViews=res.data.data.PRODUCT_VIEWS;
            this.setState({});
        });

        getproductTypeList().then(res=>{
            this.productTypes=res.data.data;
            this.setState({});
        });

    }
    
    render() {

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 200
        };

        if(this.banners!==undefined)
        {
            return (
                <div>
                    <Helmet>
                        <title>Nbiz MarketPlace</title>
                        <meta name="description" content="Nbiz – Marketplace. User can shop all products through our web portal." />
                    </Helmet>
                    {/*Home Slider*/}
                    <section className="p-0">
                        <Slider ref={slider => (this.slider = slider)} {...settings} className="slide-1 home-slider">
                            {
                                this.banners.map((bannerItem) => {
                                    console.log(imagePath+bannerItem.assetId);
                                    return (
                                        <div>
                                            <div className="home home2 text-center" style={{
                                                backgroundImage: "url(" + imagePath+bannerItem.assetId + ")"
                                            }}>
                                                <div  >
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="slider-contain">
                                                                <div className="container" style={{ "background": "#ffffffad", "height": "550px" }}>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="title1 section-t-space">
                                                                                <h4>Near by </h4>
                                                                                <h2 className="title-inner1">Sellers</h2>
                                                                                <NearBySellarList dataItems={this.nearBySellar} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </section>
                    {/*Home Section End*/}

                    {/*collection banner*/}
                   {/* <section className="pb-0">
                        <div className="container">
                            <div className="row partition2">
                                <div className="col-md-6">
                                    <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                        <div className="collection-banner p-right text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/sub-banner1.jpg`} className="img-fluid" alt=""/>
                                            <div className="contain-banner">
                                                <div>
                                                    <h4>save 30%</h4>
                                                    <h2>men</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`}>
                                        <div className="collection-banner p-right text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/sub-banner2.jpg`} className="img-fluid" alt=""/>
                                            <div className="contain-banner">
                                                <div>
                                                    <h4>save 60%</h4>
                                                    <h2>women</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>*/}
                    {/*collection banner end*/}

                    {/*Blog Section end*/}
                   

                    {/*Blog Section End*/}

                    {this.featuredProducts!=null && this.featuredProducts.length>0?
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="title1 section-t-space">
                                    <h2 className="title-inner1">Featured Products</h2>
                                   
                                </div>
                               
                            </div>
                            <section className="section-b-space">
                    <div className="container">
                        <div className="row search-product">
                        {this.featuredProducts!==undefined && this.featuredProducts.map((product, index) =>
                                <div className={`${this.state.columnSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+this.state.columnSize}`} key={index}>
                                    <ProductItem product={product} 
                                                 key={index}/>
                                </div>)
                            }
                        </div>
                    </div>
                </section>    
                        </div>
                    </div>
                    :<div></div>
        }

                


                    {/*Parallax banner*/}
                   {/* <section className="p-0">
                        <div className="full-banner parallax-banner1 parallax text-center p-left">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="banner-contain">
                                            <h2>2018</h2>
                                            <h3>fashion trends</h3>
                                            <h4>special offer</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>*/}
                    {/*Parallax banner End*/}

                 {/*   <SpecialProducts dataItems={this.productTypes} />*/}

                    <ProductTabs dataItems={this.productTypes} />
                   
                    <TopCollection items ={this.productViews}></TopCollection>
                   
                   {/* <TopCollection type={'women'} />*/}

                    {/*service layout*/}
                    <div style={{padding:"15px"}}>
                        <section className="service border-section small-section ">
                            <div className="row">
                                <div className="col-md-4 service-block">
                                    <div className="media">
                                        <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                        <div className="media-body">
                                            <h4>free shipping</h4>
                                            <p>free shipping world wide</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 service-block">
                                    <div className="media">
                                        <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                        <div className="media-body">
                                            <h4>24 X 7 service</h4>
                                            <p>online service for new customer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 service-block">
                                    <div className="media">
                                        <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                        <div className="media-body">
                                            <h4>festival offer</h4>
                                            <p>new online special festival offer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    {/*<Instagram />*/}

                    {/*logo section*/}
                  {/*  <LogoBlock />*/}
                    {/*logo section end*/}



                </div>

            )
        }
        else
        {
            return (
                <div/>
            )
        }

	}
}

export default Fashion;
