

import api from "./api";


export const getCartProducts = () => {
    return api.get('/order-service/cart/');
};

export const addItemsTocart = (data) => {
    return api.post('/order-service/cart/', data);
};

export const updateItemsTocart = (data) => {
    return api.put('/order-service/cart/', data);
};

export const getCartItems = () => {
    return api.get('/order-service/cart/');
};

export const removeCart = (cartItemId) => {
    return api.delete('/order-service/cart/item/' + cartItemId);
};

export const getOrders = (id) => {

    return api.get('/order-service/order/customer/summary?includeAll=true&customerId=' + id);

};

export const orderDetails = (orderId) => {
    return api.get('/order-service/order/track/' + orderId);
}

export const orderDelete = (orderId, businessId) => {
    return api.delete('/order-service/order/' + orderId + '/business/' + businessId + '?reason=not interested');
}

export const placeOrder = (data) => {
    return api.post('/order-service/order/', data);
};

