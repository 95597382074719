import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import moment from "moment";
import Cookies from "universal-cookie";
import { orderDelete } from '../../api/orderServices';
import { imagePath } from "../../util/Constants";
import {Link} from 'react-router-dom'

class OrderDetail extends Component{

    constructor(props){
        super(props );
        this.state={
            orderDetail: this.props.history.location.state.orderDetail,
        }
    }

    

    render(){

        return (
            <div>

            <Breadcrumb title={"my order"} />

               
               
                                {this.state.orderDetail.subOrders.map(item => {
                                return (
                                    <div>
                                    
                                    {item.orderedProducts.map(item1=>{
                                    return (

                                        <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12" style={{ width: '20%'}}>
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col" >image</th>
                                        <th scope="col">product name</th>
                                        <th scope="col">quantity</th>
                                        <th scope="col">variation</th>
                                        <th scope="col" >price</th>
                                        <th scope="col">total Price</th>
                                        <th scope="col">sold by</th>
                                       
                                        
                                    </tr>
                                    </thead>
                                  
                                        <tbody key>
                                            <tr>
                                                <td>
                                                    <Link to={`${process.env.PUBLIC_URL}/product/${item1.pcode}`}>
                                                        <img src={item1.primaryAssetId?
                                                                  imagePath+item1.primaryAssetId
                                                                  :""} alt="" />
                                                    </Link>
                                                </td>
                                              
                                                <td>
                                                <Link to={`${process.env.PUBLIC_URL}/product/${item1.pcode}`}><h5>{item1.productName}</h5></Link>
                                               
                                               </td>
                                                <td>
                                                        <h5>{item1.quantity}</h5>
                                                </td>
                                                <td>
                                                        <h5>{item1.variation!=null && item1.variation.variation}</h5>
                                                </td>
                                                <td>
                                                <h5>{parseFloat(item1.price).toFixed(2)}</h5>
                                                </td>
                                                <td><h5 className="td-color">{parseFloat(item1.totalAmount).toFixed(2)}</h5></td>
                                                <td><h5 className="td-color">{item.businessName}</h5></td>
                                            </tr>
                                        </tbody> 
                                
                                </table>
                               
                            </div>
                        </div>
                        
                    </div>
                </section>                             
                  )
              })}
          </div>
        )
    })}
   
</div>
)
}


}

function cancelOrder(orderId,businessId){

    const cookie = new Cookies();
        console.log(orderId);
        console.log(businessId);
        try {
        orderDelete(orderId,businessId).then(res =>
            {
                    if(res.data.status==="Success")
                    {
                        console.log(res.data.data);
                    }
                    
            });
        }
        catch (e) {
            
        }

}



export default OrderDetail;

