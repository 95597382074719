import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";
import {userLogin} from "../../api/userServices";
import Cookies from 'universal-cookie';
import {Link} from "react-router-dom";
import {ToastContainer,toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import {getCartProducts} from "../../api/orderServices";

class Login extends Component {

    constructor (props) {
        super (props);
        this.state = {
            loginId:null,
            password:null,
            cartItems:null
        };
        this.onInputchange=this.onInputchange.bind(this);
        this.onSubmitForm=this.onSubmitForm.bind(this);
    }

    render (){


        return (
            <div>
                <Breadcrumb title={'Login'}/>
                
                
                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Login</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Mobile Number</label>
                                            <input type="text" className="form-control"  name="loginId" onChange={this.onInputchange} id="email" placeholder="Mobile No"
                                                   required="" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input type="password" className="form-control" name="password" onChange={this.onInputchange} id="review"
                                                   placeholder="Enter your password" required="" />
                                        </div>
                                        <div className="form-group row">
                                           <div className="col-md-6">
                                           <button className="btn btn-solid" onClick={this.onSubmitForm} >Login</button>
                                        </div>
                                        <div className="col-md-5" style={{
                                                   position: 'absolute', left: '50%', top: '80%',
                                                   transform: 'translate(-50%, -50%)'}}>
                                            <Link to={`${process.env.PUBLIC_URL}/pages/forget-password` } style={{color: 'red'}}>Forgot Password?</Link>
                                        </div>
                                        </div>

                                        
                        
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create A Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                        allows you to be able to order from our shop. To start shopping click
                                        register.</p>

                                        <button className="btn btn-solid" onClick={() => this.props.history.push("/register")} >Create an Account</button>

                                </div>
                            </div>
                           
                        </div>
                    </div>
                </section>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmitForm(e)
    {
        e.preventDefault();
        let data={
            "loginId":this.state.loginId,
            "password":this.state.password,
            "appType":"CUSTOMER",
            "keepAlive":true
        };

        userLogin(data).then(res =>
        {   
            
            console.log(res.data.data);
            if(res.data.status==="Success")
            {
                const cookies = new Cookies();
                cookies.set('userId', res.data.data.id);
                cookies.set('userName', res.data.data.fullName);
                cookies.set('secToken', res.data.message);
                cookies.set('loginId', res.data.data.loginId);
                console.log(cookies.get('userId'));
                console.log(cookies.get('userName'));
                console.log(cookies.get('secToken'));
                console.log(cookies.get('cartItems'));
                toast.success("User Logged in successfully.");
                
                getCartProducts().then(res =>
                    {
                        if(res.data.status==="Success")
                        {
                            const cookies = new Cookies();
                            this.setState({cartItems:res.data.data.cartItems});
                            cookies.set('cartItems', this.state.cartItems);
            
                        }
            
                    });

                window.location.href = '/';
                
            }
            else
            {
                toast.error(res.data.message);
            }

        });
    }

}

export default Login
