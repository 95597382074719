import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'


import Breadcrumb from "../common/breadcrumb";
import {getCartTotal} from "../../services";
import {imagePath} from "../../util/Constants";
import {removeFromCart} from '../../actions';
import {getCartProducts, removeCart, updateItemsTocart} from "../../api/orderServices";

class cartComponent extends Component {

    constructor (props) {
        super (props)
        this.state = {
            cartItems:null
        }
    }

    componentDidMount() {

        getCartProducts().then(res =>
        {
            if(res.data.status==="Success")
            {
                this.setState({cartItems:res.data.data.cartItems});

            }

        });
    }

    decrementQty(item , index)
     {
         console.log("decrementQty");
         if (item.quantity  >1)
         {
            item.quantity = item.quantity-1
             item.currentTotalPrice=item.unitPrice*item.quantity;
             var cartItems = this.state.cartItems;
            cartItems[index] = item;
             updateItemsTocart(cartItems[index] ).then(res =>
             {
                 if(res.data.status==="Success")
                 {
                     this.setState({cartItems: cartItems})
                 }

             });

    }
}

     incrementQty(item , index)
    {
            console.log("incrementQty");
            item.quantity  = item.quantity  + 1;
            item.currentTotalPrice=item.unitPrice*item.quantity;
            var cartItems = this.state.cartItems;
            cartItems[index] = item;
           updateItemsTocart(cartItems[index]).then(res =>
           {
               if(res.data.status==="Success")
               {
                   this.setState({cartItems: cartItems})
               }

           });

    }

    removeItemFromCart(item , index)
    {
        console.log("Remove"+ index);
        var cartItems = this.state.cartItems;
        cartItems.splice(index, 1);
        console.log(cartItems.length);
        removeCart(item.cartItemId).then(res =>
        {
            if(res.data.status==="Success")
            {
                this.setState({cartItems: cartItems});
                this.props.removeFromCart(item.productId);
            }

        });
        this.setState({cartItems: cartItems})
    }

    render (){

        const {cartItems,removeFromCart, symbol, total} = this.props;
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Nbiz | Cart </title>
                    <meta name="description" content="Nbiz – cart" />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={'Cart Page'}/>

                {this.state.cartItems!=null && this.state.cartItems.length>0?
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col">image</th>
                                        <th scope="col">product name</th>
                                        <th scoppe="col">variation</th>
                                        <th scope="col">price</th>
                                        <th scope="col">quantity</th>
                                        <th scope="col">total</th>
                                        <th scope="col">action</th>
                                    </tr>
                                    </thead>
                                    {this.state.cartItems.map((item, index) => {
                                        return (
                                        <tbody key={index}>
                                            <tr>
                                                <td>
                                                    <Link to={`${process.env.PUBLIC_URL}/product/${item.pcode}`}>
                                                    <img src={item.imageId? imagePath+item.imageId :""} alt="" />

                                                    </Link>
                                                </td>
                                                        <td><Link to={`${process.env.PUBLIC_URL}/product/${item.pcode}`}>{item.productName}</Link>
                                                      
                                                        <td className="td-color">Sold by:{item.businessName}</td>
                                                      
                                                </td>
                                                <td><h2>
                                                    {item.variation!=null && item.variation.variation}</h2>
                                                </td>
                                                <td><h2>{symbol}{parseFloat(item.currentSellingPrice).toFixed(2)}</h2></td>
                                                <td>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                            <span className="input-group-prepend">
                                                            <button type="button" className="btn quantity-left-minus" onClick={() => this.decrementQty(item,index)} data-type="minus" data-field="">

                                                                 <i className="fa fa-angle-left"></i>
                                                                </button>
                                                            </span>
                                                            <input type="text" name="quantity" value={item.quantity} readOnly={true} className="form-control input-number" />

                                                            <span className="input-group-prepend">
                                                            <button className="btn quantity-right-plus" onClick={() => this.incrementQty(item, index)}  data-type="plus" disabled={(item.qty >= item.stock)? true : false}>

                                                            <i className="fa fa-angle-right"></i>
                                                            </button>
                                                           </span>
                                                        </div>
                                                    </div>{(item.qty >= item.stock)? 'out of Stock' : ''}
                                                </td>
                                               
                                                <td><h2 className="td-color">{symbol}{parseFloat(item.currentTotalPrice).toFixed(2)}</h2></td>
                                                <td>
                                                    <a  className="icon" onClick={() => this.removeItemFromCart(item,index)}>
                                                        <i className="fa fa-times"></i>
                                                    </a>
                                                </td>

                                            </tr>
                                        </tbody> )
                                    })}
                                </table>
                                <table className="table cart-table table-responsive-md">
                                    <tfoot>
                                    <tr>
                                        <td>total price :</td>
                                        <td><h2>{symbol}{parseFloat(gettotal(this.state.cartItems)).toFixed(2)} </h2></td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="row cart-buttons">
                            <div className="col-6">
                            <Link to={`${process.env.PUBLIC_URL}`} className="btn btn-solid">continue shopping</Link>

                            </div>
                            <div className="col-6">
                            <Link to={`${process.env.PUBLIC_URL}/pages/address`} className="btn btn-solid">check out</Link>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                        <h3>
                                            <strong>Your Cart is Empty</strong>
                                        </h3>
                                        <h4>Explore more shortlist some items.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
            </div>
        )
    }
}

function gettotal(cartList) {
    let subtotal=0;
    cartList.map((item,index) => (
        subtotal+=item.quantity*item.currentSellingPrice));

    //subtotal=subtotal+
    return subtotal;
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromCart}
)(cartComponent)
