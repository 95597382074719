import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import Cookies from "universal-cookie";
import { updateProfile ,getProfile} from "../../api/userServices";
import DatePicker from "react-datepicker";
import { imagePath } from "../../util/Constants";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName:null,
      userImageId:null,
      emailId:null,
      loginId:null,
      dob : new Date()
    }
    this.updateUser = this.updateUser.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    getProfile().then(res =>
    {
        if(res.data.status==="Success")
        {  
            this.setState({fullName:res.data.data.fullName});
            this.setState({userImageId:res.data.data.userImageId});
            this.setState({emailId:res.data.data.emailId});
            this.setState({loginId:res.data.data.loginId});
            this.setState({dob:Date.parse(res.data.data.dob.toString())})
        }
        
    });
}

  render() {

    return (
     
      <div>
        
        {console.log(this.date)}
        <Breadcrumb title={"my account"} />

        {/*Forget Password section*/}
        <section className="pwd-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <h2>My Account</h2>

                {(this.state.userImageId) != null ?
                    <div>
                      <img src={imagePath + this.state.userImageId}  alt="" style={{width:'150px',height:'150px',"object-fit": "contain"}}/>
                    </div>

                    :
                    <div className="col-md-12">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/avtar.jpg`} className="img-fluid" alt="" style={{width: "150px", height: "150px",padding:"10px"}}/>
                    </div>
                }
                <form className="theme-form">
                  <div className="form-row">

                    <div className="col-md-12">
                      <input
                        type="text"
                        id="fullName"
                        className="form-control"
                        name="fullName"
                        placeholder="Name"
                        value={this.state.fullName}
                        onChange={this.onInputchange}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        id="emailId"
                        className="form-control"
                        placeholder="EmailId"
                        name="emailId"
                        value={this.state.emailId}
                        onChange={this.onInputchange}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Registered Number"
                        readOnly={true}
                        name="loginId"
                        value={this.state.loginId}
                        onChange={this.onInputchange}
                      />
                    </div>
                    
                    <div className="col-md-12">
                      <DatePicker
                        className="form-control"
                        dateFormat="dd-MM-yyyy"
                        selected={this.state.dob}
                        onChange={this.handleChange}
                        name="dob"
                        
                      />
                   </div>
                    <button className="btn btn-solid" onClick={this.updateUser}>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChange(date) {
    this.setState({
      dob: date,
    });
  }

  updateUser(e) {
    const cookies = new Cookies();
    e.preventDefault();
    let data = {
      loginId: this.state.loginId,
      fullName: this.state.fullName,
      dob: this.state.dob,
      emailId: this.state.emailId,
      id: cookies.get("userId"),
    };

    updateProfile(data).then((res) => {
      if (res.data.status === "Success") {
        this.props.history.push("/");
      }
    });
  }
}

export default MyAccount;
