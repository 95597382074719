import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import Modal from 'react-responsive-modal';
import SmallImages from "./small-image";
import StarRatingComponent from "react-star-rating-component";
import Cookies from "universal-cookie";
import {toast, ToastContainer} from "react-toastify";
import {addToCart} from "../../../../actions";
import {addItemsTocart,getCartProducts} from "../../../../api/orderServices";
import { connect } from 'react-redux'


class DetailsWithPrice extends Component {

    constructor (props) {
        super (props)
        this.handleChange=this.handleChange.bind(this);
        this.state = {
            props:props,
            open:false,
            quantity:1,
            stock: 'InStock',
            nav3: null,
            cartItems:[],
            variationValue:null
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });

        if(this.props.item.variationData!=null && this.props.item.variationData.length>0){
            this.state.variationValue=this.props.item.variationData[0].variation

        }
        getCartProducts().then(res =>
            {
                if(res.data.status==="Success")
                {
                    this.setState({cartItems:res.data.data.cartItems});
                    console.log(this.state.cartItems);
                }
    
            });
    }

    minusQty = () => {
        if(this.state.quantity > 1) {
          //  this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
       // if(this.props.item.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        //}else{
            //this.setState({stock: 'Out of Stock !'})
        //}
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    addItemsToCart=(product,quantity)=> {

        try {
            console.log("Add to cart");
            const cookies = new Cookies();
            let userId=cookies.get('userId');
            console.log(userId);
            if(userId===null||userId===undefined)
            {
                toast.error("Please login");
                window.location.assign("/signin");
            }
            else {
                let data = {};
                let variation={};
                if(product.variationData!=null){
                    product.variationData.forEach(variant => {
                        if(variant.variation==this.state.variationValue){
                            let variationName=variant.variation;
                            let variationId=variant.variationId;
                            variation={
                                "variation":variationName,
                                "variationId":variationId
                            }
                            data.variation=variation;   
                        }
                    });
                }
                data.businessId = product.businessId;
                data.homeDelivery = product.homeDelivery;
                data.productId = product.productId;
                data.unitPrice = product.sellingPrice;
                data.quantity = quantity;
                data.totalPrice = product.sellingPrice * data.quantity;
                data.userId = cookies.get('userId');
                addItemsTocart(data).then(res => {
                    if (res.data.status === "Success") {
                        this.setState({cartItems:res.data.data.cartItems});
                        this.state.cartItems.map((product, key) => {
                            if(product.productId==data.productId){
                                 this.props.addToCart(product,product.quantity);
                            }
                           })
                       
                        toast.success("Item added to cart");
                        window.location.assign("/cart");
                    }
                    else
                    {
                        toast.error(res.data.message)
                    }
                });
            }
        }
        catch (e) {
            console.log(e.toString());
        }
    }

    buyNow=(product,quantity)=> {

        try {
            console.log("Buy Now");
            const cookies = new Cookies();
            let userId=cookies.get('userId');
            if(userId===null||userId===undefined)
            {
                toast.error("Please login")
            }
            let data = {};
            let variation={};
            if(product.variationData!=null){
                product.variationData.forEach(variant => {
                    if(variant.variation==this.state.variationValue){
                        let variationName=variant.variation;
                        let variationId=variant.variationId;
                        variation={
                            "variation":variationName,
                            "variationId":variationId
                        }
                        data.variation=variation;   
                    }
                });
            }
            data.businessId = product.businessId;
            data.homeDelivery = product.homeDelivery;
            data.productId = product.productId;
            data.unitPrice =product.sellingPrice;
            data.quantity = quantity;
            data.totalPrice = product.sellingPrice *    data.quantity;
            data.userId = cookies.get('userId');
            addItemsTocart(data).then(res => {
                if (res.data.status === "Success") {
                    this.setState({cartItems:res.data.data.cartItems});
                        this.state.cartItems.map((product, key) => {
                            if(product.productId==data.productId){
                                 this.props.addToCart(product,product.quantity);
                            }
                           })
                    toast.success("Item added to cart")
                    window.location.assign("/cart");
                }
                else
                {
                    toast.error(res.data.message)
                }
            });
        }
        catch (e) {
            console.log(e.toString());
        }
    }
    handleChange(e) {
        this.setState({ variationValue: e.target.value });
        console.log(this.state.variationValue);
      }

    render (){
        console.log(this.state.variationValue);
        const {symbol, item, addToCart, BuynowClicked, addToWishlistClicked} = this.props

        let RatingStars=[];
        console.log("Rating ="+item.averageProductRating);
       /* if(item.averageProductRating!==undefined)
        {
            generateRating(RatingStars,item.averageProductRating);
        }*/

        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide:true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        var productsnav = {
            vertical: this.state.vertical,
            verticalSwiping: this.state.vertical,
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.product-right-slick',
            arrows: false,
            infinite: true,
            centerMode: false,
            dots: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        return (
            <div className="col-lg-6 rtl-text">
                <div className="product-right">
                    <h2> {item.name} </h2>
                    <StarRatingComponent
                        name="rating"
                        starCount={5}
                        editing={false}
                        starColor="#ffb400"
                        renderStarIcon={() => <i className="fa fa-star"></i>}
                        renderStarIconHalf={() => <i className="fa fa-star-half-o" style={{"color":"#ffb400"}}></i>}
                        emptyStarColor="#e9ecef"
                        value={item.averageProductRating}
                    />
                   {/* <div className="rating">
                        {RatingStars}
                    </div>*/}
                    {item.variationData==null || item.variationData.length==0?
                    <div>{(item.businessId!=null)?
                        <div>
                        <h4>{symbol}{item.sellingPrice}
                        <del>{item.sellingPrice===item.writtenPrice?"":symbol +item.writtenPrice}</del>
                           <span>{item.sellingPrice===item.writtenPrice?"":item.discount!=null && item.discount!=undefined && item.discount+ '% off'}</span></h4>
                           
                           </div>
                            :
                           <h4>{symbol}{item.sellingPrice}
                           <del><span className="money">{ item.sellingPrice===item.writtenPrice?"":" "+symbol+item.writtenPrice}</span></del>
                           </h4>
                            }
                            </div>
                             :
                            <div>
                                 
                        {
                            item.variationData.map((v, index) =>
                                <div key={index}>
                                    {v.variation==this.state.variationValue?
                                    <div>
                                    <h4>{symbol}{v.sellingPrice}
                                    <del>{v.sellingPrice===v.writtenPrice?"":symbol +v.writtenPrice}</del>
                                       <span>{v.sellingPrice===v.writtenPrice?"":v.discount!=null && v.discount!=undefined && v.discount+ '% off'}</span></h4>
                                       
                                       </div>
                                       :
                                       <div>
                                           </div>}
                                  
                                </div>
                            )
                        }
                                
                   {item.variationData.length>0?
                   <div>
                    <h6 className="product-title">{item.variationName}</h6>
                    <select id="variation" name="variation" value={this.state.variationValue} style={{"width": "30%","height": "40px","border-radius": "25px","padding":"10px","margin-bottom":"10px","margin-top":"10px"}} onChange={this.handleChange}>
                    {item.variationData.map((e, key) => {
                     return <option key={key} value={e.variation}>{e.variation}</option>
                    })}
                    </select>
                    </div>:
                    <div>
                        </div>
                
                }


                            </div>}
                    

                    {/*{item.variants?
                    <ul >
                        <Slider {...colorsnav} asNavFor={this.props.navOne} ref={slider => (this.slider1 = slider)} className="color-variant">
                            {item.variants.map((vari, i) => {
                                return <li className={vari.color} key={i} title={vari.color}></li>
                            })}
                        </Slider>
                    </ul>:''}*/}

                    <div className="product-description border-product">
                        {item.size?
                            <div>
                                <h6 className="product-title size-text">select size
                                    <span><a href="#" data-toggle="modal"
                                             data-target="#sizemodal" onClick={this.onOpenModal} >size chart</a></span></h6>
                                <div className="modal fade" id="sizemodal" tabIndex="-1"
                                     role="dialog" aria-labelledby="exampleModalLabel"
                                     aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered"
                                         role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"
                                                    id="exampleModalLabel">Sheer Straight
                                                    Kurta</h5>
                                                <button type="button" className="close"
                                                        data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt="" className="img-fluid"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="size-box">
                            <ul>
                                {item.size.map((size, i) => {
                                    return <li key={i}><a href="#">{size}</a></li>
                                })}
                            </ul>
                        </div>
                            </div>:''}
                        <span className="instock-cls">{this.state.stock}</span>
                        <h6 className="product-title">quantity</h6>
                        <div className="qty-box">
                            <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button type="button" className="btn quantity-left-minus" onClick={this.minusQty} data-type="minus" data-field="" style={{"border-top-left-radius": "25px","border-bottom-left-radius": "25px"}}>
                                     <i className="fa fa-angle-left"></i>
                                    </button>
                                  </span>
                                <input type="text" name="quantity" value={this.state.quantity} onChange={this.changeQty} className="form-control input-number" />
                                <span className="input-group-prepend">
                                <button type="button" className="btn quantity-right-plus" onClick={this.plusQty} data-type="plus" data-field="" style={{"border-top-right-radius": "25px","border-bottom-right-radius": "25px"}}>
                                <i className="fa fa-angle-right"></i>
                                </button>
                               </span>
                            </div>
                        </div>
                    </div>
                   {(item.businessId!=null)?
                   <div>
                    {this.state.cartItems.find(data => data.productId == item.productId && data.businessId == item.businessId)?
                        <div className="product-buttons" >
                        <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-solid">buy now</Link>
                    </div>
                    :
                    <div className="product-buttons" >
                    <a className="btn btn-solid" onClick={() => this.addItemsToCart(item,this.state.quantity)}>add to cart</a>
                    <a  className="btn btn-solid" onClick={() => this.buyNow(item,this.state.quantity,this.state)} >buy now</a>{/*to={`${process.env.PUBLIC_URL}/checkout`}*/}
                    </div>                  
                    }
                   </div>:
                   <div></div>
                
                }
    
                    <div className="border-product">
                        <h6 className="product-title">product details</h6>
                        <p>{item.description}</p>
                    </div>
                    <div className="border-product">
                        <h6 className="product-title">share it</h6>
                        <div className="product-icon">
                            <ul className="product-social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="https://plus.google.com/discover" target="_blank"><i className="fa fa-google-plus"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                            </ul>
                                {/*<button className="wishlist-btn" onClick={() => addToWishlistClicked(item)}><i
                                    className="fa fa-heart"></i><span
                                    className="title-font">Add To WishList</span>
                                </button>*/}
                        </div>
                    </div>

                   {/* <div className="border-product">
                        <h6 className="product-title">Time Reminder</h6>
                        <div className="timer">
                            <p id="demo">
                                <span>25
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Days</span>
                                </span>
                                <span>22
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Hrs</span>
                                </span>
                                <span>13
                                    <span className="padding-l">:</span>
                                    <span className="timer-cal">Min</span>
                                </span>
                                <span>57
                                    <span className="timer-cal">Sec</span>
                                </span>
                            </p>
                        </div>
                    </div>*/}
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                            </div>
                            <div className="modal-body">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            
        )
    }
   
}


function generateRating(RatingStars,rating) {

    for(var i = 0; i < rating; i++) {
        RatingStars.push(<i className="fa fa-star" key={i}></i>)
    }

}

const mapStateToProps = (state) => ({
    cart: state.cartList.cart,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps,
    {addToCart}
)(DetailsWithPrice)

