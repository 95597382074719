import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {setPassword,resendOTP} from "../../api/userServices";
import Cookies from "universal-cookie";
import {toast,message} from "react-toastify";

class GeneratePassword extends Component {
 
    constructor (props) {
        super (props)
        this.onInputchange=this.onInputchange.bind(this);
        this.forgotPassword=this.forgotPassword.bind(this);
        this.resendOtp=this.resendOtp.bind(this);
       
      
    }

    render (){

        const cookies = new Cookies();
        return (
            <div>
                <Breadcrumb title={'reset password'}/>
                
                
                {/*Forget Password section*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Reset Your Password</h2>
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="otp" name="otp" onChange={this.onInputchange}
                                                   placeholder="Enter Your OTP" required="" />
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" id="newPassword" name="newPassword" onChange={this.onInputchange}
                                                   placeholder="Enter Your New Password" required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" onChange={this.onInputchange}
                                                   placeholder="Re-Enter Your New Password" required="" />
                                        </div>

                                          <div class="col-md-5">
                                           <button className="btn btn-solid" onClick={this.forgotPassword}>Submit</button>
                                        </div>
                                        <div class="col-md-5">
                                            <button className="btn btn-solid" onClick={this.resendOtp}>Resend otp</button>
                                        
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value

        });
    }
    
    forgotPassword(e)
    {
        e.preventDefault();
        const loginId = this.props.history.location.state.loginId;
        if(this.state.newPassword !== this.state.confirmPassword){
            toast.message('password can not be match');
        }else{

            setPassword(loginId,this.state.otp,this.state.newPassword).then(res =>
                {
                    if(res.data.status==="Success")
                    {
                        toast.success(res.data.message);
                        this.props.history.push("/");
                    }
                });

        }
       
       
    }
    resendOtp(e){
        e.preventDefault();
        const loginId = this.props.history.location.state.loginId;
        console.log();
        resendOTP(loginId).then(res=>{
            if(res.data.status==="Success")
                    {
                        toast.success(res.data.message);
                    }

        })
    }

}

export default GeneratePassword