import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {changePassword} from "../../api/userServices";
import Cookies from "universal-cookie";
import {ToastContainer,toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

class ResetPassword extends Component {
    
    constructor (props) {
        super (props)
        this.onInputchange=this.onInputchange.bind(this);
        this.onResetPassword=this.onResetPassword.bind(this);
       
    }

   

    render (){

        const cookies = new Cookies();
        return (
            <div>
                <Breadcrumb title={'change password'}/>
                
                
                {/*Forget Password section*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Change Your Password</h2>
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" id="oldPassword" name="oldPassword" onChange={this.onInputchange}
                                                   placeholder="Enter Your old Password" required="" />
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" id="newPassword" name="newPassword" onChange={this.onInputchange}
                                                   placeholder="Enter Your New Password" required="" />
                                        </div>
                                        <div className="col-md-12">
                                            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" onChange={this.onInputchange}
                                                   placeholder="Re-Enter Your New Password" required="" />
                                        </div>
                                           <button className="btn btn-solid" onClick={this.onResetPassword}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value

        });
    }
    
    onResetPassword(e)
    {
        e.preventDefault();
        const cookies = new Cookies();
        const loginId = cookies.get('loginId');
        console.log(loginId);
        if(this.state.newPassword !== this.state.confirmPassword){
            toast.error('Password can not be match');
        }else{
            changePassword(loginId,this.state.newPassword).then(res =>
                {
                    if(res.data.status==="Success")
                    {
                        this.props.history.push("/");
                    }
                });
        }
        
       
    }
}

export default ResetPassword