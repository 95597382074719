import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator';
import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist} from '../../actions'
import {getCartProducts, placeOrder} from "../../api/orderServices";
import {toast, ToastContainer} from "react-toastify";
import Cookies from "universal-cookie";
import {getCartTotal} from "../../services";
import { getAddress } from '../../api/userServices';
import {removeFromCart} from '../../actions';
import {imagePath} from "../../util/Constants";

class checkOut extends Component {

    constructor (props) {
        super (props)

        this.state = {
            payment:'stripe',
            addresses:null,
            address:null,
            cartItems:[],
            billingAddress:null
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {

        getAddress().then(res =>
        {
            if(res.data.status==="Success")
            {
                this.setState({addresses:res.data.data});
                console.log(this.state.addresses);
                this.state.addresses.map((item) => {
                    if(this.state.address==item.id){
                        this.setState({billingAddress:item});
                    }
                }); 
            }

        });

        getCartProducts().then(res => {
            if (res.data.status === "Success") {
                this.setState({cartItems: res.data.data.cartItems});
            } else {

            }

        });
    }
    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);

      }

      setStateFromCheckbox = (event) => {
          var obj = {};
          obj[event.target.name] = event.target.checked;
          this.setState(obj);

          if(!this.validator.fieldValid(event.target.name))
          {
              this.validator.showMessages();
          }
        }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    StripeClick = () => {

        /*if (this.validator.allValid()) {
            alert('You submitted the form and stuff!');

            var handler = (window).StripeCheckout.configure({
                key: 'pk_test_glxk17KhP7poKIawsaSgKtsL',
                locale: 'auto',
                token: (token: any) => {
                    console.log(token)
                      this.props.history.push({
                          pathname: '/order-success',
                              state: { payment: token, items: this.props.cartItems, orderTotal: this.props.total, symbol: this.props.symbol }
                      })
                }
              });
              handler.open({
                name: 'Multikart',
                description: 'Online Fashion Store',
                amount: this.amount * 100
              })
        } else {
          this.validator.showMessages();
          // rerender to show messages for the first time
          this.forceUpdate();
        }*/
    }

    placeOrder=()=>{

        console.log("Place order");
        console.log(this.state.cartItems);
        console.log(this.state.billingAddress);
        const cookies = new Cookies();
        let orderData={};
        let cartProducts=[];
        let shippingAddress={};
        shippingAddress.fullAddress=this.state.billingAddress.address.fullAddress;
        shippingAddress.landmark=this.state.billingAddress.address.landmark;
        shippingAddress.locality=this.state.billingAddress.address.locality;
        shippingAddress.area=this.state.billingAddress.address.area;
        shippingAddress.city=this.state.billingAddress.address.city;
        shippingAddress.state=this.state.billingAddress.address.state;
        shippingAddress.country=this.state.billingAddress.address.country;
        shippingAddress.pincode=this.state.billingAddress.address.pincode;

        {this.state.cartItems.map((item, index) => {
            let products={};
            products.businessId=item.businessId;
            products.productId=item.productId;
            products.quantity=item.quantity;
            products.sellingPrice=item.unitPrice;
            cartProducts.push(products);
        })
        }
        orderData.cartProducts=cartProducts;
        orderData.customerId=cookies.get("userId");
        orderData.latitude=0;
        orderData.longitude=0;
        orderData.paymentType="CASH_ON_DELIVERY";
        orderData.saveShippingAddress=true;
        orderData.shippingAddress=shippingAddress;
        //orderData.shippingAddressName;
        console.log(orderData)
        placeOrder(orderData).then(res => {
            if (res.data.status === "Success") {
                window.location.href="/order-success";
            }
            else
            {
                toast.error(res.data.message)
            }
        });

    }


    render (){
        if(this.props.history.location.state!=null){
            this.state.address=this.props.history.location.state.address;
        }
        const {symbol,removeFromCart, total} = this.props;

            return (
                <div>

                    {/*SEO Support*/}
                    <Helmet>
                    <title>Nbiz | CheckOut </title>
                    <meta name="description" content="Nbiz – Checkout" />

                    </Helmet>
                    {/*SEO Support End */}

                    <Breadcrumb title={'Checkout'}/>

                    {this.state.cartItems != null && this.state.cartItems.length > 0 ?
                        <section className="section-b-space">
                            <div className="container padding-cls">
                                <div className="checkout-page">
                                    <div className="checkout-form">
                                        <form>
                                            <div className="checkout row">
                                                <div className="col-lg-6 col-sm-12 col-xs-12">
                                                    <div className="checkout-details">
                                                        <div className="order-box">
                                                            <div className="title-box">
                                                                <div>Product <span> Total</span></div>
                                                            </div>
                                                            <ul className="qty">
                                                                {this.state.cartItems.map((item, index) => {
                                                                    return <li
                                                                        key={index}>
                                                                          <img
                                    src={item.imageId ?imagePath+item.imageId:`${process.env.PUBLIC_URL}/assets/images/icon/placeholder.png`}
                                    className="img-fluid" style={{width:'60px',height:'60px',"object-fit": "contain"}}
                                    alt="" />
                                                                           
                                                                        <span>{symbol} {item.quantity * item.unitPrice}</span>
                                                                        <span>{item.productName} × {item.quantity}</span> 
                                                                    </li>
                                                                })
                                                                }
                                                            </ul>
                                                            <ul className="sub-total">
                                                                <li>Subtotal <span
                                                                    className="count">{symbol}{getSubtotal(this.state.cartItems)}</span>
                                                                </li>
                                                                {/*<li>Shipping <div className="shipping">
                                                                <div className="shopping-option">
                                                                    <input type="checkbox" name="free-shipping"
                                                                           id="free-shipping"/>
                                                                    <label htmlFor="free-shipping">Free
                                                                        Shipping</label>
                                                                </div>
                                                                <div className="shopping-option">
                                                                    <input type="checkbox" name="local-pickup"
                                                                           id="local-pickup"/>
                                                                    <label htmlFor="local-pickup">Local
                                                                        Pickup</label>
                                                                </div>
                                                            </div>
                                                            </li>*/}
                                                            </ul>

                                                            <ul className="total">
                                                                <li>Total <span
                                                                    className="count">{symbol}{getSubtotal(this.state.cartItems)}</span>
                                                                </li>
                                                            </ul>

                                                            <div className="total">
                                                                <button type="button" className="btn btn-solid"
                                                                        onClick={() => {
                                                                            this.placeOrder();
                                                                            this.state.cartItems.map((item, index) => {
                                                                                removeFromCart(item.productId)
                                                                            })
                                                                        }} style={{
                                                                    "float": "right",
                                                                    "margin-top": "-10px"
                                                                }}>Place Order
                                                                </button>
                                                            </div>

                                                        </div>


                                                        {/*  <div className="payment-box">
                                                        <div className="upper-box">
                                                            <div className="payment-options">
                                                                <ul>
                                                                    <li>
                                                                        <div className="radio-option stripe">
                                                                            <input type="radio"
                                                                                   name="payment-group"
                                                                                   id="payment-2"
                                                                                   defaultChecked={true}
                                                                                   onClick={() => this.checkhandle('stripe')}/>
                                                                            <label
                                                                                htmlFor="payment-2">Stripe</label>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="radio-option paypal">
                                                                            <input type="radio"
                                                                                   name="payment-group"
                                                                                   id="payment-1"
                                                                                   onClick={() => this.checkhandle('paypal')}/>
                                                                            <label
                                                                                htmlFor="payment-1">PayPal<span
                                                                                className="image"><img
                                                                                src={`${process.env.PUBLIC_URL}/assets/images/paypal.png`}
                                                                                alt=""/></span></label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                         {(total !== 0)?
                                                                <div className="text-right">
                                                                    {(this.state.payment === 'stripe')? <button type="button" className="btn-solid btn" onClick={() => this.StripeClick()} >Place Order</button>:
                                                                        <PaypalExpressBtn env={'sandbox'} client={client} currency={'USD'} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />}
                                                                </div>
                                                                : ''}
                                                    </div>*/}

                                                    </div>
                                                </div>
                                            </div>
                                            {/*  <div className="row section-t-space">
                                        <div className="col-lg-6">
                                            <div className="stripe-section">
                                                <h5>stripe js example</h5>
                                                <div>
                                                    <h5 className="checkout_class">dummy test</h5>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>cart number</td>
                                                                <td>4242424242424242</td>
                                                            </tr>
                                                            <tr>
                                                                <td>mm/yy</td>
                                                                <td>2/2020</td>
                                                            </tr>
                                                            <tr>
                                                                <td>cvc</td>
                                                                <td>2222</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 m-sm-t-2">
                                            <div className="stripe-section">
                                                <h5>paypal example</h5>
                                                <div>
                                                    <h5 className="checkout_class">dummy test</h5>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>cart number</td>
                                                                <td>4152521541244</td>
                                                            </tr>
                                                            <tr>
                                                                <td>mm/yy</td>
                                                                <td>11/18</td>
                                                            </tr>
                                                            <tr>
                                                                <td>cvc</td>
                                                                <td>521</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <section className="cart-section section-b-space">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div>
                                            <div className="col-sm-12 empty-cart-cls text-center">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                                                     className="img-fluid mb-4" alt=""/>
                                                <h3>
                                                    <strong>Your Cart is Empty</strong>
                                                </h3>
                                                <h4>Explore more shortlist some items.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }

                    <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                </div>
            );


        // Paypal Integration
       /* const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: '/order-success',
                    state: { payment: payment, items: this.state.cartItems, orderTotal: total, symbol: symbol }
            })

        }

        const onCancel = (data) => {
            console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
            console.log("Error!", err);
        }

        const client = {
            sandbox:    'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
        }*/

        return (<div></div>)

    }
}

function getSubtotal(cartList) {

    let subtotal=0;
    cartList.map((item,index) => (
        subtotal+=item.quantity*item.unitPrice));

    return subtotal;
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromWishlist,removeFromCart}
)(checkOut)
