import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";
import {createUser} from "../../api/userServices";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";

class Register extends Component {


    constructor (props) {
        super (props)
        this.onInputchange=this.onInputchange.bind(this);
        this.onSubmitForm=this.onSubmitForm.bind(this);
        this.state = {
            registrationId:null
        };
    }

    render (){

        const cookies = new Cookies();

        return (
            <div>
                <Breadcrumb title={'create account'}/>
                
                
                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>create account</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">First Name</label>
                                                <input type="text" className="form-control" id="fname" name="firstName" onChange={this.onInputchange}
                                                       placeholder="First Name" required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Last Name</label>
                                                <input type="text" className="form-control" id="lname" name="lastName" onChange={this.onInputchange}
                                                       placeholder="Last Name" required="" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">email</label>
                                                <input type="text" className="form-control" id="email" name="emailId" onChange={this.onInputchange}
                                                       placeholder="Email" required="" />
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <label htmlFor="review">Mobile Number</label>
                                                <input type="text" className="form-control" id="review" name="registeredMobileNumber" onChange={this.onInputchange}
                                                       placeholder="Enter your Mobile Number" required="" />
                                            </div>
                                            
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">Password</label>
                                                <input type="password" className="form-control" id="email" name="password" onChange={this.onInputchange}
                                                       placeholder="Enter your password" required="" />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Confirm Password</label>
                                                <input type="password" className="form-control" id="review" name="confirmpassword" onChange={this.onInputchange}
                                                       placeholder="Enter your password" required="" />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col-md-6">
                                                <label htmlFor="review" className="col-md-12">Gender</label>
                                                <select id="gender" name="gender" value={this.state.gender} style={{"width": "100%","height": "50px","padding": "10px","border-color":"#ddd","border-radius": "50px"}} onChange={this.onInputchange}>
                                                    <option>FEMALE</option>
                                                    <option>MALE</option>
                                                    <option>OTHER</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Country</label>
                                                <input type="text" className="form-control" id="review" name="country" onChange={this.onInputchange}
                                                       placeholder="Enter country" required="" />
                                            </div>

                                        </div>
                                        <div className="form-row">

                                            <div className="col-md-6">

                                            </div>
                                            <div style={{
                                                transform: 'translate(-60%, 70%)'}}>
                                                <button className="btn btn-solid" onClick = {this.onSubmitForm}>create Account</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmitForm(e)
    {
        e.preventDefault();
        let data={
            "emailId":this.state.emailId,
            "fullName":this.state.firstName+" "+this.state.lastName,
            "loginId":this.state.registeredMobileNumber,
            "password":this.state.password,
            "registeredMobileNumber":this.state.registeredMobileNumber,
            "gender":this.state.gender
        };

        createUser(data).then(res =>
        {
            console.log(res.data.data);
            toast.success(res.data.message);
           
            if(res.data.status==="Success")
            {
                const cookies = new Cookies();
                cookies.set('registrationId', res.data.data);
                this.props.history.push({
                    pathname : '/pages/otp-verify',
                    state :{
                        registrationId : res.data.data,
                        loginId:this.state.registeredMobileNumber,
                        password:this.state.password
                    }
                    } 
                  );
            }
        });
    }
}

export default Register
