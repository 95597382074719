import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {logout,getProfile} from "../../api/userServices";
import Cookies from 'universal-cookie';
import {toast} from "react-toastify";
import { getOrders,getCartItems } from '../../api/orderServices';
import {Link} from "react-router-dom";

class Dashboard extends Component {

    constructor (props) {
        super (props)
        this.state = {
            userData:null
        }
        this.logout=this.logout.bind(this);
        this.viewOrder=this.viewOrder.bind(this);
        this.viewCart=this.viewCart.bind(this);
        this.addressView=this.addressView.bind(this);
    }

    componentDidMount() {

        getProfile().then(res =>
        {
            if(res.data.status==="Success")
            {
                this.setState({userData:res.data.data});
                const cookies = new Cookies();
                cookies.set('dob', res.data.data.dob);
                
            }
            
        });
    }

    addressView(e){
        e.preventDefault();
        this.props.history.push({
            pathname :  '/pages/address',
            state :{
                addressFlag : false
                }
            });
    }

    render (){


        return (
            <div>
                <Breadcrumb title={'Dashboard'}/>
                
                
                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li ><a href="/pages/myAccount">My Account</a></li>
                                            <li><a href="" onClick={this.addressView}>Address Book</a></li>
                                            <li><a href="#" onClick={this.viewOrder}>My Orders</a></li>
                                            {/*<li><a href="#">My Wishlist</a></li>
                                            <li><a href="#">Newsletter</a></li>*/}
                                            <li ><a href="#" onClick={this.viewCart}>My Cart</a></li>
                                            <li><a href="/pages/changePassword">Change Password</a></li>
                                            <li className="last"><a href="#" onClick={this.logout}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {this.state.userData!=null ?
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        <div className="page-title">
                                            <h2>My Dashboard</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>Hello, {this.state.userData.fullName} !</p>
                                        </div>
                                        <div className="box-account box-info">
                                            <div className="box-head">
                                                <h2>Account Information</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="box">
                                                        <div className="box-title">
                                                            <h3>Contact Information</h3>
                                                            <Link to={`${process.env.PUBLIC_URL}/pages/myAccount`}>Edit</Link>
                                                           {/* <a href="/pages/myAccount" >Edit</a>*/}
                                                        </div>
                                                        <div className="box-content">
                                                            <h6>{this.state.userData.phoneNumber}</h6>
                                                            <h6>{this.state.userData.emailId}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<div className="col-sm-6">
                                                    <div className="box">
                                                        <div className="box-title">
                                                            <h3>Newsletters</h3>
                                                            <a href="#">Edit</a>
                                                        </div>
                                                        <div className="box-content">
                                                            <p>
                                                                You are currently not subscribed to any newsletter.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                            </div>
                                            <div>
                                                <div className="box">
                                                    <div className="box-title">
                                                        <h3>Address Book</h3>
                                                        <a href="" onClick={this.addressView}>Edit Address</a>
                                                    </div>
                                                    <h6>Default Shipping Address</h6>
                                                    {this.state.userData.addresses!=null && this.state.userData.addresses.map((item) => {
                                                            return(
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                           
                                                           
                                                            <h6>{item.addressType}</h6>
                                                           
                                                             <h6>{item.address.fullAddress}</h6>

                                                        </div>
                                                    </div>
                                                       
                                                       )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :<div></div>
                        }
                        </div>
                        
                    </div>
                </section>

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    logout(e)
    {
        e.preventDefault();
        const cookies = new Cookies();
        const loginId = cookies.get('loginId');
        logout(loginId).then(res =>
            {
                    if(res.data.status==="Success")
                    {
                        cookies.remove('loginId');
                        cookies.remove('userId');
                        toast.success(res.data.message);
                        window.location.href = '/';
                    }
            });
       
    }

        viewOrder(e){
        e.preventDefault();
        const cookies = new Cookies();
        const userId = cookies.get('userId');
        getOrders(userId).then(res =>
            {
                    if(res.data.status==="Success")
                    {
                        
                        this.props.history.push({
                        pathname :  '/pages/order',
                        state :{
                            orders : res.data.data
                            }
                            
                    } 
                 );
                }     
            });

        }

        viewCart(e){
            e.preventDefault();
            const cookies = new Cookies();
            getCartItems().then(res =>
                {
                        if(res.data.status==="Success")
                        {
                            this.props.history.push('/cart');
                        }
                            
    
            });
        }

}

export default Dashboard
