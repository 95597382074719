import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import moment from "moment";
import Cookies from "universal-cookie";
import { orderDetails } from '../../api/orderServices';

class Order extends Component{

    constructor(props){
        super(props );
        this.state={
            orders: this.props.history.location.state.orders
        }
    }


    render(){


        return (
            <div>
                 <Breadcrumb title={"my order"} />

                <section className="pwd-page section-b-space">
                <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <table className="table cart-table table-responsive-xs">
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col">Order Id</th>
                                        <th scope="col">Order Amount</th>
                                        <th scope="col">Order Status</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                    </thead>
                                    {this.state.orders.map((item, index) => {
                                        return (
                                        <tbody key={index}>
                                            <tr onClick={() => this.fetchDetails(item.orderId)}>
                                                <td>
                                                   {item.orderId}
                                                </td>
                                                <td>
                                                {parseFloat(item.orderValue).toFixed(2)}
                                                </td>
                                                <td>
                                                    {item.status}
                                                </td>
                                                <td>
                                                {moment(item.orderDate).format("DD-MM-YYYY")}
                                                </td>       
                                            </tr>
                                            </tbody> )
                                    })}
                                </table>
                               
                            </div>
                        </div>
                        
                    </div>
                </section>

            </div>

        )
    }

     fetchDetails(item){
        const cookie = new Cookies();
        console.log(item);
        try {
        orderDetails(item).then(res =>
            {
                    if(res.data.status==="Success")
                    {
                        console.log(res.data.data);
                        this.props.history.push({
                            pathname :  '/pages/order-detail',
                            state :{
                                orderDetail : res.data.data
                                }
                                
                        } 
                     );
                       
                    }    
            });
        }
        catch (e) {
            
        }
    }


}



export default Order;


