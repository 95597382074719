import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {userLogin, verifyOTP} from "../../api/userServices";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";
class OtpVerification extends Component {

    constructor (props) {
        super (props)
        this.onInputchange=this.onInputchange.bind(this);
        this.onVerifyOtp=this.onVerifyOtp.bind(this);
    }

    render (){

        const cookies = new Cookies();
        return (
            <div>
                <Breadcrumb title={'verify OTP'}/>
                
                
                {/*Forget Password section*/}
                <section className="pwd-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <h2>Enter Your OTP</h2>
                                <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <input type="text" className="form-control" id="otp" name="otp" onChange={this.onInputchange}
                                                   placeholder="Enter Your OTP" required="" />
                                        </div>
                                        <button className="btn btn-solid" onClick={this.onVerifyOtp}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    onVerifyOtp(e)
    {
        e.preventDefault();

       const registrationId = this.props.history.location.state.registrationId;
       const loginId = this.props.history.location.state.loginId;
       const password = this.props.history.location.state.password;    
        const cookies = new Cookies();
        verifyOTP(registrationId,this.state.otp).then(res =>
        {
            console.log(res.data.data);
            toast.success(res.data.message);
            if(res.data.status==="Success")
            {
                cookies.set('registrationId', res.data.data);
                let loginData={
                    "loginId":loginId,
                    "password":password,
                    "appType":"CUSTOMER",
                    "keepAlive":true
                };
        
                userLogin(loginData).then(res =>
                {
                    console.log(res.data.data);
                    if(res.data.status==="Success")
                    {
                        const cookies = new Cookies();
                        cookies.set('userId', res.data.data.id);
                        cookies.set('userName', res.data.data.fullName);
                        cookies.set('secToken', res.data.message);
                        cookies.set('loginId', res.data.data.loginId);
                        console.log(cookies.get('userId'));
                        console.log(cookies.get('userName'));
                        console.log(cookies.get('secToken'));
                        toast.success(res.data.message);
                        this.props.history.push("/");
                    }
                    else
                    {
                        toast.error(res.data.message);
                    }
        
                });

            }
        });
    }

}

export default OtpVerification