import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {connect} from 'react-redux'

import {getBestSeller, getMensWear, getWomensWear} from '../../../services/index'
import {addToCart, addToWishlist, addToCompare} from "../../../actions/index";
import ProductItem from './product-item';
import {getProductsBysearch} from "../../../api/productServices";

class ProductsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            productTypeItem:{},
            products:[]
        };
    }


    render (){

        const {bestSeller,mensWear,womensWear, symbol, addToCart, addToWishlist, addToCompare,dataItem} = this.props;
        this.productTypeItem=dataItem;
        if(this.productTypeItem!==undefined )
        {
            if(this.products===undefined || this.products.length===0)
            {
                let data={};
                let productTypeCodes =[];
                productTypeCodes.push(this.productTypeItem.code);
                data.productTypeCodes=productTypeCodes;
                getProductsBysearch(data,1,20).then(res=>{
                //getproductListByCategoryId('5fa25f50e4b0e5fd0b0fc112').then(res => {
                    this.products = res.data.data;
                    if(this.products.length>12)
                    {
                        this.products = this.products.slice(0, 12)
                    }

                    this.setState({products: res.data.data});
                    return (
                        <div  style={{ "padding": "25px" }}>
                            {this.products.map((product, index) =>

                                <div className={`${'col-lg-' + 2}`} key={index}>
                                <ProductItem product={product} symbol={symbol}
                                             onAddToCompareClicked={() => addToCompare(product)}
                                             onAddToWishlistClicked={() => addToWishlist(product)}
                                    onAddToCartClicked={() => addToCart(product, 1)} key={index} /> </div>)
                            }
                        </div>
                    );

                });
            }
            else
            {
                return (
                    <div className="no-slider row" style={{ "padding": "25px" }}>
                        {this.products.map((product, index) =>
                            <ProductItem product={product} symbol={symbol}
                                         onAddToCompareClicked={() => addToCompare(product)}
                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                         onAddToCartClicked={() => addToCart(product, 1)} key={index}/>)
                        }
                    </div>
                );
            }

            return (
                <div/>)
        }
        else
        {
            return (
                <div/>)
        }
    }
}


const mapStateToProps = (state) => ({
    bestSeller: getBestSeller(state.data.products),
    mensWear: getMensWear(state.data.products),
    womensWear: getWomensWear(state.data.products),
    symbol: state.data.symbol
})

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare}) (ProductsList);
