import axios from 'axios';
import * as Constants from "../util/Constants";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export default axios.create({
    headers: {"fprnt":"5dcda110e4b0d9f7f8223969",
    "userName":cookies.get('loginId'),"secToken":cookies.get('secToken')},
    baseURL: Constants.baseurl,
    responseType: "json"
});
