import React, { Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import { addAddress,getAddress,deleteUserAddress ,editUserAddress} from "../../api/userServices";
import Cookies from "universal-cookie";


class Address extends Component{
   
    constructor(props) {
        super(props);
        this.onInputchange = this.onInputchange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addUserAddress=this.addUserAddress.bind(this);
        this.editAddress=this.editAddress.bind(this);
        this.checkout=this.checkout.bind(this);
        this.onAddressChanged = this.onAddressChanged.bind(this);
        this.state={
            addresses: null,
            addAddress: true,
            address:null,
            name:null,
            area:null,
            city:null,
            country:null,
            fullAddress:null,
            landmark:null,
            locality:null,
            pincode:null,
            state:null,
            id:null,
            addressFlag:true
            
        }
    }

    componentDidMount() {

        getAddress().then(res =>
            {
                    if(res.data.status==="Success")
                    {
                        this.setState({addresses:res.data.data});
                            
                }     
            });
    }

    changeAddress(address){
        this.setState({addAddress:false});
        this.state.addresses.map((item) => {
        if(address==item.id){
            this.state.name=item.name;
            this.state.area=item.address.area;
            this.state.city=item.address.city;
            this.state.country=item.address.country;
            this.state.fullAddress=item.address.fullAddress;
            this.state.landmark=item.address.landmark;
            this.state.locality=item.address.locality;
            this.state.pincode=item.address.pincode;
            this.state.state=item.address.state;
            this.state.id=item.id
        }
    }); 

    }

   

    deleteAddress(id){
        const cookie = new Cookies();
                try {
                    console.log(id);
                    deleteUserAddress(id).then(res =>
                        {
                                if(res.data.status==="Success")
                                {
                                        getAddress().then(res =>
                                            {
                                                if(res.data.status==="Success"){
                                                    console.log(res.data.data);
                                                    window.location.reload();
                                                }
                                
                                            });
                                        
                                    } 
                                  
                        });
                }
                catch (e) {
                    
                }
        }

        onAddressChanged (address) {
            this.setState({
                address: address
                });
            console.log(this.state.address);
          }
      
    render() {
        if(this.props.history.location.state!=null){
            this.state.addressFlag=false;
        }
        const { addAddress } = this.state;
        return (
            <div>
                <Breadcrumb title={'Manage Address'}/>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <li ><a style ={{color:"red", right: "50%"}} onClick={this.handleChange}> ADD/EDIT A NEW ADDRESS</a></li>
                </div>
               {!addAddress && ( <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme-card">
                                    <form className="theme-form">
                                    <div className="form-row">
                                        <div className="col-md-6">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" className="form-control" id="name" value={this.state.name} name="name" onChange={this.onInputchange}
                                                       placeholder="Name" required="" />
                                    </div>
                                       
                                    <div className="col-md-6">
                                                <label htmlFor="locality">Locality</label>
                                                <input type="text" className="form-control" id="locality" value={this.state.locality} name="locality" onChange={this.onInputchange}
                                                       placeholder="Locality" required="" />
                                    </div>
                                    </div>
                                    <div className="form-row">
                                             <div className="col-md-6">
                                                <label htmlFor="area">Area</label>
                                                <input type="text" className="form-control" id="area" value={this.state.area} name="area" onChange={this.onInputchange}
                                                       placeholder="Area" required="" />
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <label htmlFor="landmark">Landmark</label>
                                                <input type="text" className="form-control" id="landmark" value={this.state.landmark} name="landmark" onChange={this.onInputchange}
                                                       placeholder="Landmark" required="" />
                                            </div>
                                            </div>

                                            <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="city">City</label>
                                                <input type="text" className="form-control" id="city" value={this.state.city} name="city" onChange={this.onInputchange}
                                                       placeholder="City" required="" />
                                            </div>
                                           
                                            <div className="col-md-6">
                                                <label htmlFor="pincode">Pincode</label>
                                                <input type="text" className="form-control" id="pincode" value={this.state.pincode} name="pincode" onChange={this.onInputchange}
                                                       placeholder="Pincode" required="" />
                                            </div>
                                            </div>

                                            <div className="form-row">

                                            <div className="col-md-6">
                                                <label htmlFor="address">State</label>
                                                <input type="text"  value={this.state.state} className="form-control" id="state" name="state" onChange={this.onInputchange}
                                                       placeholder="Address" required="" />
                                            </div>

                                            <div className="col-md-6">
                                                <label htmlFor="address">Address</label>
                                                <textarea type="text" style={{height:"70px",width:"500px"}} value={this.state.fullAddress} className="form-control" id="fullAddress" name="fullAddress" onChange={this.onInputchange}
                                                   placeholder="Address" required="" />
                                            </div>

                                           
                                            
                                                </div>
                                            
                                     {(this.state.id==null)?
                                     <div style={{
                                        transform: 'translate(40%, 50%)'}}>
                             <button className="btn btn-solid" onClick = {this.addUserAddress}>Save</button>
                                     
                             </div>
                                     :
                                     <div style={{
                                        transform: 'translate(40%, 50%)'}}>
                             <button className="btn btn-solid" onClick = {this.editAddress}>Update</button>
                             </div>
                                    }  
                                       
                                      
                                    </form>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </section>
                
               )}

            {this.state.addresses!=null && this.state.addresses.map((item) => {
                return(
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme-card">
                                <div className="radio-option">
                                        <input type="radio" name="adress" id="address" onClick={()=>this.onAddressChanged(item.id)} />
                                                <label htmlFor="adress" style ={{paddingLeft:"10px"}} ><h5>{ item.name}</h5></label>
                                </div>

                                    <form className="theme-form">
                                        
                                        <div className="form-row">
                                        <div className="col-md-9">
                                    <h6>{item.address.fullAddress}</h6>
                                    </div>
                                
                                    <div className="col-md-1">
                                    <li ><a style ={{color:"red", right: "50%"}} onClick={()=>this.changeAddress(item.id)}> Edit</a></li>
                                    </div>  
                                    <div className="col-md-1">
                                    <li ><a style ={{color:"red", right: "50%"}} onClick={() =>this.deleteAddress(item.id)}> Delete</a></li>
                                        </div>
                                        </div>
                                </form>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                    
                  )
                  
              })                         
            } 
           {this.state.addressFlag && <div style={{transform: 'translate(40%, 0%)'}}>
            
            <button className="btn btn-solid" onClick={this.checkout}>Continue</button>
            
            </div>
            }
            
            </div>
        
        )
    }
    checkout(e){
        e.preventDefault();
        this.props.history.push({
            pathname :  '/checkout',
            state :{
                address : this.state.address
                }
            });
    }
    handleChange() {
        this.setState({
            addAddress: !this.state.addAddress
        })
    }

    onInputchange(event) {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }

     addUserAddress(e){
        const cookie = new Cookies();
        e.preventDefault();
        const userId = cookie.get('userId');
        let address={
            "area":this.state.area,
            "city":this.state.city,
            "country":this.state.country,
            "fullAddress":this.state.fullAddress,
            "landmark":this.state.landmark,
            "locality":this.state.locality,
            "pincode":this.state.pincode,
            "state":this.state.state
            
          }
        let data={
            "address":address,
            "userId":userId,
            "name":this.state.name,
            "latitude":0,
            "longitude":0
        };
        addAddress(data).then(res =>
            {
                    if(res.data.status==="Success")
                    {
                        console.log(res.data.data);
                        window.location.reload();
                                     
                    }     
            });

        }

        editAddress(e){
             e.preventDefault();
            const cookie = new Cookies();
            const userId = cookie.get('userId');
           
            let address={
                "area":this.state.area,
                "city":this.state.city,
                "country":this.state.country,
                "fullAddress":this.state.fullAddress,
                "landmark":this.state.landmark,
                "locality":this.state.locality,
                "pincode":this.state.pincode,
                "state":this.state.state
                
              }
            let data={
                "address":address,
                "userId":userId,
                "name":this.state.name,
                "id":this.state.id,
                "latitude":"16.404566301729513",
                "longitude":"74.38731763511896"
            
                }
                console.log(data);
                editUserAddress(data).then(res =>
                    {
                    if(res.data.status==="Success")
                    {
                        console.log(res.data.data);
                        window.location.reload();
                        
                       
                    }    
            });
            
        }

    }

    

export default Address;
