import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class TopBarDark extends Component {


    render() {
        return (
            <div className="top-header top-header-dark3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>Welcome to Our Nbiz Market place</li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>Call Us: +91 8484817600</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li className="mobile-wishlist"><a href="#"><i className="fa fa-heart"
                                                                               aria-hidden="true"></i> wishlist</a></li>
                               {/* <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i> My Account
                                    <ul className="onhover-show-div">
                                        <li>
                                            <a href="#" data-lng="en">
                                                Login
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" data-lng="es">
                                                Register
                                            </a>
                                        </li>
                                    </ul>
                                </li>*/}
                                <li className="onhover-dropdown mobile-account">
                                    {/* <i className="fa fa-user" aria-hidden="true"></i> {translate('my_account')}*/}
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/pages/dashboard`} data-lng="en">My Account</Link>
                                    </li>
                                </li>
                                <li className="onhover-dropdown mobile-account">
                                    <li>
                                        <Link to={`${process.env.PUBLIC_URL}/signin`} data-lng="en">Login</Link>
                                    </li>
                                </li>
                                {/* <ul className="onhover-show-div">

                                        <li>
                                            <Link to={`${process.env.PUBLIC_URL}/register`} data-lng="en">Register</Link>
                                        </li>
                                    </ul>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default TopBarDark;
