import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';


import { getTotal, getCartProducts } from '../../../reducers'
import { addToCart, addToWishlist, addToCompare } from '../../../actions'
import {getVisibleproducts} from '../../../services';
import ProductListItem from "./product-list-item";
import {getProductsBysearch,getBusinessProductsByBusinessId} from "../../../api/productServices";

class ProductListing extends Component {

    constructor (props) {
        super (props)

        this.state = {page:1, limit: 20, business:null,productType:null, hasMoreItems: true,productData:[] };

    }

    componentWillMount(){
        this.fetchMoreItems();
    }

    fetchMoreItems = () => {

        /* if (this.state.limit >= this.props.products.length) {
           this.setState({ hasMoreItems: false });
           return;
       }*/
      
       // a fake async api call
      
       setTimeout(() => {
           this.setState({
               page:this.state.page+1,
               limit:this.state.limit+5
           });
       },1000);

       if(this.props.business!=null){
       getBusinessProductsByBusinessId(this.props.business,this.state.page,this.state.limit).then(res =>
        {
            if(res.data.status==="Success") {
                    console.log(res.data.data);
                    this.setState({productData : this.state.productData.concat(res.data.data)});
            }else {
                    return (
                        <div></div>
                    )
            }
        });
        }

        else if(this.props.productType!=null){
        getProductsBysearch(this.props.productType,this.state.page,this.state.limit).then(res=>{
            console.log(this.state.page+" "+this.state.limit)
            
            if(res.data.status==="Success")
            {
                this.setState({productData : this.state.productData.concat(res.data.data)});
            } else {
                return (
                    <div></div>
                )
            }
        });

       }

   }

    render (){
        const {data, addToCart, symbol, addToWishlist, addToCompare} = this.props;
        //console.log(this.props.colSize);
        //console.log(this.props.data);
        //console.log("Product length = "+this.props.products.length);
        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {this.state.productData?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                loader={this.state.productData.length>0?<div className="loading-cls"></div>:<div></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <div className="row">
                                    {this.state.productData.slice(0, this.state.limit).map((product, index) =>
                                        <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+this.props.colSize}`} key={index}>
                                        <ProductListItem product={product} symbol={symbol}
                                                         onAddToCompareClicked={() => addToCompare(product)}
                                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                                         onAddToCartClicked={addToCart} key={index}/>
                                        </div>)
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" style={{width:'100px',height:'100px',"object-fit": "contain"}}/>
                                    <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                                    <p>Please check if you have misspell something or try searching with other words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(ProductListing)
