import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import FilterBar from "../../../components/collection/common/filter-bar";
import ProductListing from "../../../components/collection/common/product-listing";
import {getProductsBysearch} from "../../../api/productServices";
import Breadcrumb from "../../common/breadcrumb";

class RecentlyViewList extends Component {

    constructor () {
        super ();
        this.state = {
            layoutColumns:3,
            productData:null,
            data:{}
        };
    }

    componentWillReceiveProps(newProps)
    {
        console.log("Url changes");
        this.state.productData=null;
        this.forceUpdate();
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }  

    render (){
        console.log("ProductType Id = "+this.props.match.params.code);
       
        if(this.state.productData===null)
        {
            let productTypeCodes = [];
            productTypeCodes.push(this.props.match.params.code);
            this.state.data.productTypeCodes=productTypeCodes;
            getProductsBysearch(this.state.data,1,50).then(res=>{

                if(res.data.status==="Success")
                {
                    this.state.productData = res.data.data;
                    this.setState({});

                } else {
                    return (
                        <div></div>
                    )
                }
            });
            return(
                <div>
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div >
                                        <div style={{height:"300px"}}>
                                            <div className="loading-cls"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )


        }
        else
        {
            return (
                <div>
                    {/*SEO Support*/}
                    <Helmet>
                        <title>Nbiz – MarketPlace</title>
                        <meta name="description" content="Nbiz – MarketPlace" />
                    </Helmet>
                    {/*SEO Support End */}

                    <Breadcrumb title={'Products'}/>

                    <section className="section-b-space">
                        <div className="collection-wrapper">
                            <div className="container">
                                <div className="row">
                                  {/*  <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            <Filter/>
                                            <NewProduct/>
                                            <div className="collection-sidebar-banner">
                                                <a href="#">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </StickyBox>
                                    side-bar banner end here
                                </div>*/}
                                    <div className="collection-content col">
                                        <div className="page-main-content ">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                     {/*   <div className="top-banner-wrapper">
                                                            <a href="#"><img src={imagePath+this.state.businessData.asset[0].assetId} className="img-fluid" alt=""/></a>
                                                            <div className="top-banner-content small-section">
                                                                <h4>{this.state.businessData.name}</h4>
                                                                <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                                                                <p>{this.state.businessData.description}</p>
                                                            </div>
                                                        </div>*/}
                                                        <div className="collection-product-wrapper">
                                                            <div className="product-top-filter">
                                                                <div className="container-fluid p-0">
                                                                    <div className="row">
                                                                        <div className="col-xl-12">
                                                                            <div className="filter-main-btn">
                                                                            <span onClick={this.openFilter}
                                                                                  className="filter-btn btn btn-theme"><i
                                                                                className="fa fa-filter"
                                                                                aria-hidden="true"></i> Filter</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*Products Listing Component*/}
                                                            <ProductListing colSize={this.state.layoutColumns}  productType={this.state.data} data={this.state.productData}/>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            )
        }

        return(
            <div></div>
        )


    }
}

export default RecentlyViewList;
