import api from "./api";


export const getBanner = () => {

    /* {
         params: {
             latitude: 0.0,
                 longitude:0.0
         }
     }*/
    return api.get('admin-service/banner/');
};

export const getHome = () => {

    return api.get('api-gateway/customer/home/', {
        params: {
            latitude: 0.0,
            longitude: 0.0
        }
    });
};

export const getRecentlyViewed = () => {
    return api.get('products-service/productaction/recent', {
        params: {
            productCategory: "PRODUCT",
            page: 0,
            limit: 20
        }
    });
};


