import React, { Component } from 'react';
import RecentViewItem from '../common/recent-view-item';
import {getRecentlyViewed} from "../../../api/homeServices";

class TopCollection extends Component {
    constructor (props) {
        super (props)
        this.state = {
            columnSize:3
        }
        this.fetchRecentlyView=this.fetchRecentlyView.bind(this);
    }

    fetchRecentlyView(){
        getRecentlyViewed().then(res=>{
            this.recentlyView=res.data.data;
            this.setState({});
            console.log(this.recentlyView);
            window.location.assign("/recentview/products");
            //window.location.href="/recentview/products";
        })

    }

    render (){

        const {items, symbol, addToCart, addToWishlist, addToCompare} = this.props;

        return (
            <div>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="title1 section-t-space">
                                    <h2 className="title-inner1">Recently View</h2>
                                    
                                </div>
                                
                            </div>
                            {items!==undefined && items.length>4?
                            <div className="row cart-buttons">
                                <div className="col-6">
                                <button className="btn btn-solid" onClick={this.fetchRecentlyView}>View All</button>
                                </div>
                            </div>:
                            <div>
                                </div>
                            }
                            <section className="section-b-space">
                   
                </section>    
                        </div>
                </div>

                <div style={{ padding: "25px" }}>
                    <div className="row search-product">
                        {items !== undefined && items.map((product, index) =>
                            <div className={`${this.state.columnSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.state.columnSize}`} key={index}>
                                <RecentViewItem product={product}
                                    key={index} />
                            </div>)
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default TopCollection
