import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import StarRatingComponent from 'react-star-rating-component';
import { withCookies, Cookies } from 'react-cookie';
import {
    getRatingBreakUp,
    getRatingByProduct,
    getSimilarProductsByProductTypeId,
    saveRating
} from "../../../api/productServices";
import ProductItem from "../../layouts/common/product-item";
import {PRODUCT} from "../../../util/Constants";
import {ToastContainer,toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

class DetailsTopTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productsData:null,
            reviewsData:null,
            reviewsSummary:null,
            title:null,
            comments:null,
            rating:0
        };
        this.onInputchange=this.onInputchange.bind(this);
        this.onSubmitForm=this.onSubmitForm.bind(this);
    }

    render (){
        const {productsData} = this.props;
        this.state.productsData=productsData;
        let RatingStars=[];
        console.log("Rating ="+productsData.averageProductRating);
        if(this.state.productsData.averageProductRating!==undefined)
        {
            generateRating(RatingStars,this.state.productsData.averageProductRating);
        }

        if(this.state.reviewsData==null) {
            getRatingByProduct(this.state.productsData.businessId, this.state.productsData.productId).then(res => {
                this.state.reviewsData = res.data.data;
                this.setState({})
            });
        }

        if(this.state.reviewsSummary==null) {
            getRatingBreakUp(this.state.productsData.businessId, this.state.productsData.productId).then(res => {
                this.state.reviewsSummary = res.data.data.ratingBreakupDetails;
                this.setState({})
            });
        }

        return (
            <section className="tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                <Tab className="nav-item">
                                    <span className="nav-link active">
                                        <i className="icofont icofont-ui-home"></i>Features</span>
                                    <div className="material-border"></div>
                                </Tab>
                                <Tab className="nav-item">
                                    <span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>Description</span>
                                    <div className="material-border"></div>
                                </Tab>
                               {/* <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Video</span>
                                    <div className="material-border"></div>
                                </Tab>*/}
                                <Tab className="nav-item">
                                    <span className="nav-link" >
                                        <i className="icofont icofont-contacts"></i>Reviews</span>
                                    <div className="material-border"></div>
                                </Tab>

                            </TabList>
                            <TabPanel className="tab-pane fade mt-4 show active">
                                <table className="table table-striped mb-0">

                                    <tbody>
                                    {this.state.productsData.features!==null?this.state.productsData.features.map((features, index) =>
                                        <tr>
                                           {/* <th>Ideal For :</th>*/}
                                            <td>{"\u2022" + features}</td>
                                        </tr>
                                        ):""
                                    }
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel>
                                <p className="mt-4 p-0">
                                    {this.state.productsData.description!==undefined?this.state.productsData.description:""}
                                </p>
                            </TabPanel>
                          {/*  <TabPanel>
                                <div className="mt-4 text-center">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe
                                            src="https://www.youtube.com/embed/BUWzX78Ye_8"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                            </TabPanel>*/}
                            <TabPanel>
                                <section className="blog-detail-page section-b-space" style={{"padding-top": "10px"}}>

                                    <div className="container">
                                        <div className="row" style={{"align-items":"center"}}>
                                            <div className="col-sm-3">
                                                <div className="rating-block">
                                                    <h4>Average user rating</h4>
                                                    <h2 className="bold padding-bottom-7">{this.state.productsData.averageProductRating} <small>/ 5</small></h2>

                                                    <StarRatingComponent
                                                        name="rating"
                                                        starCount={5}
                                                        editing={false}
                                                        starColor="#ffb400"
                                                        renderStarIcon={() => <i className="fa fa-star"></i>}
                                                        renderStarIconHalf={() => <i className="fa fa-star-half-o" style={{"color":"#ffb400"}}></i>}
                                                        emptyStarColor="#e9ecef"
                                                        value={this.state.productsData.averageProductRating}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <h4>Rating breakdown</h4>
                                                <div className="pull-left">
                                                    <div className="pull-left" style={{"width":"35px", "line-height":"1"}}>
                                                        <div style={{"height":"9px", "margin":"5px"}}> 5
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <div className="pull-left" style={{"width":"180px"}}>
                                                        <div className="progress" style={{"height":"9px","margin":"8px 0"}}>
                                                            <div className="progress-bar progress-bar-success"
                                                                 role="progressbar" aria-valuenow="5" aria-valuemin="0"
                                                                 aria-valuemax="5" style={{"width": getPercentage(this.state.reviewsSummary,5)+"%"}}>
                                                                <span className="sr-only">80% Complete (danger)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pull-right" style={{"margin-left":"10px"}}>{getRatingCount(this.state.reviewsSummary,5)}</div>
                                                </div>
                                                <div className="pull-left">
                                                    <div className="pull-left" style={{"width":"35px", "line-height":"1"}}>
                                                        <div style={{"height":"9px", "margin":"5px"}}>4
                                                            <i className="fa fa-star"></i>
                                                        </div>
                                                    </div>
                                                    <div className="pull-left" style={{"width":"180px"}}>
                                                        <div className="progress" style={{"height":"9px","margin":"8px 0"}}>
                                                            <div className="progress-bar progress-bar-primary"
                                                                 role="progressbar" aria-valuenow="5" aria-valuemin="0"
                                                                 aria-valuemax="5" style={{"width":getPercentage(this.state.reviewsSummary,4)+"%"}}>
                                                                <span className="sr-only">80% Complete (danger)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pull-right" style={{"margin-left":"10px"}}>{getRatingCount(this.state.reviewsSummary,4)}</div>
                                                </div>
                                                <div className="pull-left">
                                                    <div className="pull-left" style={{"width":"35px", "line-height":"1"}}>
                                                        <div style={{"height":"9px", "margin":"5px"}}>3
                                                            <i className="fa fa-star"></i></div>
                                                    </div>
                                                    <div className="pull-left" style={{"width":"180px"}}>
                                                        <div className="progress" style={{"height":"9px","margin":"8px 0"}}>
                                                            <div className="progress-bar progress-bar-info"
                                                                 role="progressbar" aria-valuenow="5" aria-valuemin="0"
                                                                 aria-valuemax="5" style={{"width": getPercentage(this.state.reviewsSummary,3)+"%"}}>
                                                                <span className="sr-only">80% Complete (danger)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pull-right" style={{"margin-left":"10px"}}>{getRatingCount(this.state.reviewsSummary,3)}</div>
                                                </div>
                                                <div className="pull-left">
                                                    <div className="pull-left" style={{"width":"35px", "line-height":"1"}}>
                                                        <div style={{"height":"9px", "margin":"5px"}}>2
                                                            <i className="fa fa-star"></i></div>
                                                    </div>
                                                    <div className="pull-left" style={{"width":"180px"}}>
                                                        <div className="progress" style={{"height":"9px","margin":"8px 0"}}>
                                                            <div className="progress-bar progress-bar-warning"
                                                                 role="progressbar" aria-valuenow="5" aria-valuemin="0"
                                                                 aria-valuemax="5" style={{"width": getPercentage(this.state.reviewsSummary,2)+"%"}}>
                                                                <span className="sr-only">80% Complete (danger)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pull-right" style={{"margin-left":"10px"}}>{getRatingCount(this.state.reviewsSummary,2)}</div>
                                                </div>
                                                <div className="pull-left">
                                                    <div className="pull-left" style={{"width":"35px", "line-height":"1"}}>
                                                        <div style={{"height":"9px", "margin":"5px"}}>1
                                                            <i className="fa fa-star"></i></div>
                                                    </div>
                                                    <div className="pull-left" style={{"width":"180px"}}>
                                                        <div className="progress" style={{"height":"9px","margin":"8px 0"}}>
                                                            <div className="progress-bar progress-bar-danger"
                                                                 role="progressbar" aria-valuenow="5" aria-valuemin="0"
                                                                 aria-valuemax="5" style={{"width": getPercentage(this.state.reviewsSummary,1)+"%"}}>
                                                                <span className="sr-only">80% Complete (danger)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pull-right" style={{"margin-left":"10px"}}>{getRatingCount(this.state.reviewsSummary,1)}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.reviewsData!==null?this.state.reviewsData.map((reviews, index) =>

                                            <div className="row section-b-space" style={{"padding-top": "10px","padding-bottom": "10px"}}>
                                                <div className="col-sm-12">
                                                    <ul className="comment-section">
                                                        <li style={{"padding-top": "0px","padding-bottom": "5px"}}>
                                                            <div className="media" style={{"margin-top": "0px"}}>
                                                                <div>
                                                                    <img
                                                                        src={`${process.env.PUBLIC_URL}/assets/images/avtar.jpg`}
                                                                        alt="Generic placeholder image"/>

                                                                    <div>
                                                                        {
                                                                            console.log("Row Rating "+reviews.rating)
                                                                        }

                                                                        <StarRatingComponent
                                                                            starCount={5}
                                                                            editing={false}
                                                                            starColor="#ffb400"
                                                                            renderStarIcon={() => <i className="fa fa-star"></i>}
                                                                            renderStarIconHalf={() => <i className="fa fa-star-half-o" style={{"color":"#ffb400"}}></i>}
                                                                            emptyStarColor="#e9ecef"
                                                                            value={reviews.rating}
                                                                          />

                                                                    </div>
                                                                </div>

                                                                <div className="media-body">
                                                                    <h6>{reviews.title}
                                                                    </h6>
                                                                    <h8>{reviews.ratingDate}</h8>
                                                                    <p style={{"margin-top": "5px"}}>{reviews.comments}</p>
                                                                    <p>{reviews.userName}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>):""
                                        }

                                        <div className="row blog-contact" style={{"padding-top":"15px"}}>
                                            <div className="col-sm-12">
                                                <h2>Leave Your Ratings</h2>
                                                <form className="theme-form mt-4">
                                                    <div className="form-row">
                                                        <div className="col-md-12 ">
                                                            <div className="media m-0">
                                                               {/* <label>Rating</label>
                                                                <div className="media-body ml-3">
                                                                    <div className="rating three-star">
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                        <i className="fa fa-star"></i>
                                                                    </div>
                                                                </div>*/}

                                                               {/* <Rater rating={0} total={5}  onRate={({rating}) => {this.ratingChanged(rating)}}/>*/}

                                                            {/*    <StarRating name="handler" caption="Use onClick Handlers!" totalStars={5} onRatingClick={this.handleRatingClick} />*/}



                                                                <div>
                                                                    <StarRatingComponent
                                                                        name="rating"
                                                                        starCount={5}
                                                                        starColor="#ffb400"
                                                                        renderStarIcon={() => <i className="fa fa-star fa-3x"></i>}
                                                                        emptyStarColor="#e9ecef"
                                                                        value={this.state.rating}
                                                                        onStarClick={this.onStarClick.bind(this)}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {/*<div className="col-md-6">
                                                            <label htmlFor="name">Name</label>
                                                            <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="email">Email</label>
                                                            <input type="text" className="form-control" id="email" placeholder="Email" required />
                                                        </div>*/}
                                                        <div className="col-md-12">
                                                            <label htmlFor="review">Title</label>
                                                            <input type="text" className="form-control" name="title" onChange={this.onInputchange} value={this.state.title} id="review" placeholder="Enter your Review" required />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="review">Comments</label>
                                                            <textarea className="form-control" name="comments" onChange={this.onInputchange} value={this.state.comments} placeholder="Enter your Comments" id="exampleFormControlTextarea1" rows="6"></textarea>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <button className="btn btn-solid" onClick={this.onSubmitForm} type="submit">Submit Your Review</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
            </section>
            
        )
    }

    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
        console.log(nextValue);
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmitForm(e)
    {
        e.preventDefault();
        const cookies = new Cookies();
        const userId = cookies.get('userId');
            console.log(userId);
            if(userId===null||userId===undefined)
            {
                toast.error("Please login")
                console.log("Please login");
            }else{
                let data={
                    "productId":this.state.productsData.id,
                    "userId":cookies.get('userId'),
                    "userName":cookies.get('userName'),
                    "ratingType":PRODUCT,
                    "rating":this.state.rating,
                    "title":this.state.title,
                    "comments":this.state.comments,
                };
                saveRating(data).then(res => {
                    //this.setState({})
                    window.location.reload(false);
                });

            }
    }

}



function generateRating(RatingStars,rating) {

    for(var i = 0; i < rating; i++) {
        RatingStars.push(<i className="fa fa-star" key={i}></i>)
    }

}

function getRatingCount(RatingDetails,rating) {

    let count = 0;

    if(RatingDetails!=null) {

        for (var i = 0; i < RatingDetails.length; i++) {
            console.log(RatingDetails[i].rating === rating);
            if (RatingDetails[i].rating === rating) {
                count = RatingDetails[i].count;
            }
        }
    }

    return count;
}
function getPercentage(RatingDetails,rating) {

    let percentage=0;
    if(RatingDetails!=null) {
        for (var i = 0; i < RatingDetails.length; i++) {
            if (RatingDetails[i].rating === rating) {
                percentage = RatingDetails[i].ratingPercentage;
            }
        }
    }

    return percentage;
}


export default DetailsTopTabs;
