import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {connect} from 'react-redux'

import {getBestSeller, getMensWear, getWomensWear} from '../../../services/index'
import {addToCart, addToWishlist, addToCompare} from "../../../actions/index";
import ProductItem from './product-item';
import {getproductListByCategoryId} from "../../../api/productServices";
import ProductsList from "./products";

class ProductsTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            shuffledItems: []
        };
    }


    render (){

        const {bestSeller,mensWear,womensWear, symbol, addToCart, addToWishlist, addToCompare,dataItems} = this.props;
        if(dataItems!==undefined && dataItems.length>0)
        {
            shuffleArray(dataItems);
            if(dataItems.length>3)
            {
               this.shuffledItems = dataItems.slice(0, 3)
            }
            else
            {
                this.shuffledItems=dataItems;
            }

            return (
                <div>
                    <div className="title1 section-t-space" style={{"paddingTop":"50px"}}>
                        <h4>exclusive products</h4>
                        <h2 className="title-inner1">special products</h2>
                    </div>
                    <section className="section-b-space p-t-0">
                        <div style={{padding:"30px"}}>
                            <Tabs className="theme-tab">
                                <TabList className="tabs tab-title">

                                    {
                                        this.shuffledItems.map((productTypeItem,index) =>
                                        {
                                            return (
                                                    <Tab key={index}>{productTypeItem.name}</Tab>
                                            );
                                        })
                                    }

                                </TabList>

                               {/* <TabPanel>
                                    <div className="no-slider row">
                                        { bestSeller.map((product, index ) =>
                                            <ProductItem product={product} symbol={symbol}
                                                         onAddToCompareClicked={() => addToCompare(product)}
                                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                                         onAddToCartClicked={() => addToCart(product, 1)} key={index} /> )
                                        }
                                    </div>
                                </TabPanel>

*/}
                                {
                                    this.shuffledItems.map((productTypeItem,index) =>
                                    {

                                        return (
                                            <TabPanel key={index}>
                                                <div className="no-slider row">
                                                    <ProductsList dataItem={productTypeItem} />
                                                </div>
                                            </TabPanel>
                                        );
                                    })
                                }
                            </Tabs>
                        </div>
                    </section>
                </div>
            )
        }
        else
        {
            return (
                <div/>)
        }
    }
}

function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}


const mapStateToProps = (state) => ({
    bestSeller: getBestSeller(state.data.products),
    mensWear: getMensWear(state.data.products),
    womensWear: getWomensWear(state.data.products),
    symbol: state.data.symbol
})

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare}) (ProductsTabs);
